import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Button,
    IconButton,
    TextField,
    Select, MenuItem, InputLabel, Menu,
    Grid, Paper, Divider
} from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ReservationPopUp from './reservationpopup';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';

function ActiveReservations({ selectedReservation, visible }) {
    const [reservations, setReservations] = useState([]);
    const [reservationID, setReservationID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const fetchData = async (type) => {
        setLoading(true);
        try {
            const response = await axios.get(`/Office/Reservations?type=${type}`);
            setReservations(response.data.reservations);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false);
    }
    useEffect(() => {
        if ((visible) && (reservations.length === 0)) {
            fetchData(3)
        }
    }, [visible])

    return (
        <Box display={visible ? "flex" : "None"} flexDirection="column" bottom="0" top="0" height="100%">

            <Box display="flex" alignItems="center" marginBottom="10px">
                <TextField
                    label="Search Tasks"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ marginRight: '10px', flex: 1 }}
                />
                <IconButton onClick={() => fetchData(3)}>
                    <RefreshIcon />
                </IconButton>
            </Box>
            <Box position="relative" width="100%" height="100%">
                <DataTable globalFilter={searchQuery}
                    selectionMode="single"
                    dataKey='stayid'
                    selection={reservationID}
                    onSelectionChange={(e) => {
                        setReservationID(e.value.reservation_id);
                        selectedReservation(e.value.reservation_id);
                    }}
                    value={reservations} rowsPerPageOptions={[5, 10, 20]} scrollable scrollHeight="flex" loading={loading}
                    style={{ top: 0, bottom: 0, position: 'absolute', overflow: 'auto', width: '100%' }}>
                    <Column body={(rowdata) => {
                        return (<Avatar style={{ height: '16px', width: '16px' }} src={`https://pcrms.leviathan.systems/${rowdata.ota_image}`} />)
                    }} style={{ width: '32px' }} />
                    <Column body={(rowdata) => {
                        if (rowdata.tax_count === "") {
                            return (<Box />)
                        } else {
                            return (<Box>{rowdata.tax_count}</Box>)
                        }
                    }} />
                    <Column field='date_from' header='Date From' />
                    <Column field='date_to' header='Date To' />
                    <Column field='internal_ref' header='Ref' />
                    <Column field='channel_reservation_id' header='Channel Ref' />
                    <Column field='client_name' header='Client' />
                    <Column field='o_name' header='Name' />
                </DataTable>
            </Box>
        </Box >
    )
}

function ConfirmedReservations({ selectedReservation, visible }) {
    const [reservations, setReservations] = useState([]);
    const [reservationID, setReservationID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const fetchData = async (type) => {
        setLoading(true);
        try {
            const response = await axios.get(`/Office/Reservations?type=${type}`);
            setReservations(response.data.reservations);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false);
    }
    useEffect(() => {
        if ((visible) && (reservations.length == 0)) {
            fetchData(4)
        }
    }, [visible])

    return (
        <Box display={visible ? "flex" : "None"} flexDirection="column" bottom="0" top="0" height="100%">
            <Box display="flex" alignItems="center" marginBottom="10px">
                <TextField
                    label="Search Tasks"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ marginRight: '10px', flex: 1 }}
                />
                <IconButton onClick={() => fetchData(3)}>
                    <RefreshIcon />
                </IconButton>
            </Box>
            <Box flex={2} display="flex" flexDirection="row">
                <Box position="relative" width="100%" height="100%">
                    <DataTable globalFilter={searchQuery}
                        selectionMode="single"
                        dataKey='stayid'
                        selection={reservationID}
                        onSelectionChange={(e) => {
                            setReservationID(e.value.reservation_id);
                            selectedReservation(e.value.reservation_id);
                        }}
                        paginator
                        rows={20}
                        value={reservations} rowsPerPageOptions={[5, 10, 20]} scrollable scrollHeight="flex" loading={loading}
                        style={{ top: 0, bottom: 0, position: 'absolute', overflow: 'auto', width: '100%' }}>
                        <Column body={(rowdata) => {
                            return (<Avatar style={{ height: '16px', width: '16px' }} src={`https://pcrms.leviathan.systems/${rowdata.ota_image}`} />)
                        }} style={{ width: '32px' }} />
                        <Column body={(rowdata) => {
                            if (rowdata.tax_count === "") {
                                return (<Box />)
                            } else {
                                return (<Box>{rowdata.tax_count}</Box>)
                            }
                        }} />
                        <Column field='date_from' header='Date From' />
                        <Column field='date_to' header='Date To' />
                        <Column field='internal_ref' header='Ref' />
                        <Column field='channel_reservation_id' header='Channel Ref' />
                        <Column field='client_name' header='Client' />
                        <Column field='o_name' header='Name' />
                    </DataTable>
                </Box>
            </Box>
            <Box flex={1}>
                <Box position="relative" width="100%" height="100%">
                    <DataTable globalFilter={searchQuery}
                        selectionMode="single"
                        dataKey='stayid'
                        selection={reservationID}
                        onSelectionChange={(e) => {
                            setReservationID(e.value.reservation_id);
                            selectedReservation(e.value.reservation_id);
                        }}
                        value={[]} rowsPerPageOptions={[5, 10, 20]} scrollable scrollHeight="flex" loading={loading}
                        style={{ top: 0, bottom: 0, position: 'absolute', overflow: 'auto', width: '100%' }}>
                        <Column body={(rowdata) => {
                            return (<Avatar style={{ height: '16px', width: '16px' }} src={`https://pcrms.leviathan.systems/${rowdata.ota_image}`} />)
                        }} style={{ width: '32px' }} />
                        <Column body={(rowdata) => {
                            if (rowdata.tax_count === "") {
                                return (<Box />)
                            } else {
                                return (<Box>{rowdata.tax_count}</Box>)
                            }
                        }} />
                        <Column field='date_from' header='Date From' />
                        <Column field='date_to' header='Date To' />
                        <Column field='internal_ref' header='Ref' />
                        <Column field='channel_reservation_id' header='Channel Ref' />
                        <Column field='client_name' header='Client' />
                        <Column field='o_name' header='Name' />
                    </DataTable>
                </Box>

            </Box>
        </Box>
    )
}

export default function ReservationsDashboard() {
    const [visibleReservation, setVisibleReservation] = useState(false);
    const [reservationID, setReservationID] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box display="flex" flexDirection="row" flex="1" width="100%" height="100%" margin="5px">
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedTab}
                onChange={handleTabChange}
                style={{ borderRight: '1px solid #ddd', top: '0', bottom: '0' }}
            >
                <Tab label="Active Reservations" />
                <Tab label="Confirmed Reservations" />
            </Tabs>
            <Box height="100%" width="100%" flex="1" position="relative" display='flex' flexDirection='column' pr={2}>
                <ActiveReservations selectedReservation={(e) => {
                    setReservationID(e);
                    setVisibleReservation(true);
                }} visible={selectedTab === 0} />
                <ConfirmedReservations selectedReservation={(e) => {
                    setReservationID(e);
                    setVisibleReservation(true);
                }} visible={selectedTab === 1} />
            </Box>
            <ReservationPopUp onclose={() => setVisibleReservation(false)} reservationID={reservationID} visible={visibleReservation} />
        </Box>
    );
}

