import React, { useEffect, useState, useCallback } from 'react';
import {
    Box, Typography, Button, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, TextField, IconButton, Tooltip, Snackbar, Alert
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { debounce } from 'lodash';
import { orangeColor, CommonSX } from '../../css/common_sx';
function CheckoutSchedule() {
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(() => {
        const initialEndDate = new Date();
        initialEndDate.setDate(initialEndDate.getDate() + 14);
        return initialEndDate;
    });
    const [error, setError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    function getDate(date) {
        return date.toLocaleDateString('en-US');
    }

    const fetchdata = async (date_from, date_to) => {
        const formattedStartDate = getDate(date_from);
        const formattedEndDate = getDate(date_to);
        try {
            let response = await axios.get(`/Office/Reservations/stat?type=2&date_from=${formattedStartDate}&date_to=${formattedEndDate}`);
            setJobs(response.data.reservations);
            setFilteredJobs(response.data.reservations); // Set filtered jobs to the full list initially
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchdata(startDate, endDate);
    }, [startDate, endDate]);

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
        fetchdata(newDate, endDate);
    };

    const handleEndDateChange = (newDate) => {
        setEndDate(newDate);
        fetchdata(startDate, newDate);
    };

    // Debounced search function
    const handleSearchChange = useCallback(
        debounce((searchText) => {
            const filtered = jobs.filter((job) =>
                job.o_name.toLowerCase().includes(searchText.toLowerCase()) ||
                job.internal_ref.toLowerCase().includes(searchText.toLowerCase())
            );
            setFilteredJobs(filtered);
        }, 300), // 300ms delay
        [jobs]
    );

    const handleSearchInput = (e) => {
        const searchText = e.target.value;
        setSearchTerm(searchText);
        handleSearchChange(searchText);
    };

    const downloadCsv = async () => {
        try {
            const formattedStartDate = getDate(startDate);
            const formattedEndDate = getDate(endDate);
            const url = `/Office/Reservations/stat?type=2&date_from=${formattedStartDate}&date_to=${formattedEndDate}&resType=csv`;

            const response = await axios.get(url, { responseType: 'blob' });

            const blob = new Blob([response.data]);
            const urlObject = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = urlObject;
            a.download = 'downloadedFile.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading CSV:', error);
            setError(true);
        }
    };

    return (
        <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
            <Box display="flex" flexDirection="row" alignItems="center" mb={1} bgcolor={orangeColor} p={1} borderRadius={1}>
                <Typography>Date From:</Typography>
                <DatePicker
                    value={startDate}
                    onChange={handleStartDateChange}
                    sx={{
                        ...CommonSX,
                        '& .MuiInputBase-root': { fontSize: '0.875rem', padding: '4px 8px' }
                    }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                />
                <Typography>Date To:</Typography>
                <DatePicker
                    value={endDate}
                    onChange={handleEndDateChange}
                    sx={{
                        ...CommonSX,
                        '& .MuiInputBase-root': { fontSize: '0.875rem', padding: '4px 8px' }
                    }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                />
                <TextField
                    label="Search"
                    value={searchTerm}
                    onChange={handleSearchInput}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{
                        bgcolor: 'white',
                        borderRadius: 1
                    }}
                />
                <Button
                    size="small"
                    sx={{ ml: 2, fontSize: '0.75rem', padding: '4px 8px' }}
                    variant="contained"
                    onClick={downloadCsv}>
                    Download CSV
                </Button>
            </Box>
            <TableContainer component={Paper} style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Ref</TableCell>
                            <TableCell align='center'>Date From</TableCell>
                            <TableCell align='center'>Date To</TableCell>
                            <TableCell align='center'>Property Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredJobs.map((job, index) => (
                            <TableRow key={index}>
                                <TableCell>{job.internal_ref}</TableCell>
                                <TableCell>{job.date_from}</TableCell>
                                <TableCell>{job.date_to}</TableCell>
                                <TableCell>{job.o_name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => { setOpenSnackbar(false) }}>
                <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                    Error fetching data!
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default CheckoutSchedule;

