import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Select, MenuItem, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import axios from 'axios';

const DocumentStatusTypeEditorDialog = ({ open, onClose }) => {
    const [documentTypes, setDocumentTypes] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [statusAccesses, setStatusAccesses] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [changes, setChanges] = useState([]);  // Track changes for confirmation
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    // Fetch data
    useEffect(() => {
        const fetchData = async () => {
            const [docTypes, statusesRes, accesses] = await Promise.all([
                axios.get('/Office/CRM/Documents/types'),
                axios.get('/Office/CRM/Documents/statuses'),
                axios.get('/Office/CRM/Documents/status_accesses'),
            ]);
            setDocumentTypes(docTypes.data);
            setStatuses(statusesRes.data);
            setStatusAccesses(accesses.data);
        };
        if (open) fetchData();
    }, [open]);

    // Handle document type change
    const handleDocumentTypeChange = (event) => {
        setSelectedDocumentType(event.target.value);
    };

    // Handle toggle of allowed status transitions for selected document type
    const handleToggleAllow = (srcStatusId, dstStatusId) => {
        const accessIndex = statusAccesses.findIndex(access =>
            access.src_status === srcStatusId &&
            access.dst_status === dstStatusId &&
            access.type_id === selectedDocumentType
        );

        let updatedAccesses;
        let newAllow = true;

        if (accessIndex === -1) {
            // If the access doesn't exist, create a new one
            const newAccess = {
                id: `${srcStatusId}-${dstStatusId}-${selectedDocumentType}`,
                src_status: srcStatusId,
                dst_status: dstStatusId,
                type_id: selectedDocumentType,
                allowed: true,
            };
            updatedAccesses = [...statusAccesses, newAccess];
        } else {
            // Toggle the allow field
            updatedAccesses = statusAccesses.map((access, index) => {
                if (index === accessIndex) {
                    newAllow = !access.allowed;
                    return { ...access, allowed: newAllow };
                }
                return access;
            });
        }

        setStatusAccesses(updatedAccesses);

        // Track the changes
        const newChange = {
            srcStatus: srcStatusId,
            dstStatus: dstStatusId,
            documentType: selectedDocumentType,
            allowed: newAllow,
        };

        setChanges(prevChanges => {
            const isDuplicate = prevChanges.some(
                change =>
                    change.srcStatus === newChange.srcStatus &&
                    change.dstStatus === newChange.dstStatus &&
                    change.documentType === newChange.documentType
            );
            if (isDuplicate) return prevChanges;
            return [...prevChanges, newChange];
        });
    };

    // Handle save confirmation dialog
    const handleSave = () => {
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmSave = () => {
        for (const change of changes) {
            axios.put('/Office/CRM/Documents/status_accesses', change)
        }
        setIsConfirmDialogOpen(false);
        onClose();  // Close the editor dialog
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
                <DialogTitle>Document Status Access Editor</DialogTitle>
                <DialogContent>
                    {/* Select for Document Type */}
                    <Typography variant="subtitle1">Select Document Type:</Typography>
                    <Select
                        value={selectedDocumentType}
                        onChange={handleDocumentTypeChange}
                        fullWidth
                        sx={{ mb: 2 }}
                    >
                        {documentTypes.map((docType) => (
                            <MenuItem key={docType.id} value={docType.id}>
                                {docType.document_name}
                            </MenuItem>
                        ))}
                    </Select>

                    {/* The grid/table */}
                    <Box overflow='auto' max-width='50vw' max-height='40vh'>

                        <Table size="small">
                            <TableHead>
                                {/* Header: Statuses */}
                                <TableRow>
                                    <TableCell sx={{ padding: '4px' }}></TableCell>
                                    {statuses.map((status) => (
                                        <TableCell key={status.status_value} align="center" sx={{ padding: '4px', fontSize: '12px' }}>
                                            {status.status_name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Table Body: Source Status to Destination Status with Checkboxes */}
                                {statuses.map((srcStatus) => (
                                    <TableRow key={srcStatus.status_value}>
                                        <TableCell sx={{ padding: '4px', fontSize: '12px' }}>{srcStatus.status_name}</TableCell>
                                        {statuses.map((dstStatus) => {
                                            const access = statusAccesses.find(
                                                a => a.src_status === srcStatus.status_value &&
                                                    a.dst_status === dstStatus.status_value &&
                                                    a.type_id === selectedDocumentType
                                            );
                                            return (
                                                <TableCell key={`${srcStatus.id}-${dstStatus.id}`} align="center" sx={{ padding: '2px' }}>
                                                    <Checkbox
                                                        checked={access ? access.allowed : false}
                                                        disabled={srcStatus.status_value === dstStatus.status_value}
                                                        onChange={() => handleToggleAllow(srcStatus.status_value, dstStatus.status_value)}
                                                        sx={{ padding: '0', transform: 'scale(0.8)' }}  // Smaller checkbox
                                                    />
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSave} variant="contained" color="primary">Save Changes</Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Confirm Changes</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">You have made the following changes:</Typography>
                    <List>
                        {changes.map((change, index) => {
                            const srcName = statuses.find(s => s.id === change.srcStatus)?.status_name || "Unknown";
                            const dstName = statuses.find(s => s.id === change.dstStatus)?.status_name || "Unknown";
                            const docTypeName = documentTypes.find(dt => dt.id === change.documentType)?.document_name || "Unknown";
                            const allowStatus = change.allowed ? "Allowed" : "Disallowed";
                            return (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={`Source: ${srcName}, Destination: ${dstName}, Document Type: ${docTypeName}`}
                                        secondary={`Status Transition: ${allowStatus}`}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                    <Divider />
                    <Typography variant="body2" color="textSecondary">
                        Confirm if you want to proceed with these changes.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsConfirmDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleConfirmSave} variant="contained" color="primary">Confirm and Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DocumentStatusTypeEditorDialog;

