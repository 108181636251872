import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Button,
    IconButton,
    TextField,
    Select, MenuItem, InputLabel, Menu,
    Grid, Paper, Divider
} from '@mui/material';
import { DataTable } from 'primereact/datatable';
import AddIcon from '@mui/icons-material/Add';
import { Sidebar } from 'primereact/sidebar';
import InfoIcon from '@mui/icons-material/Info';
import ArticleIcon from '@mui/icons-material/Article';
import { ArrowCircleRightOutlined, DeleteRounded } from '@mui/icons-material';
import { Column } from 'primereact/column';
//import { Button } from 'primereact/button';
import axios from 'axios';
import { Avatar } from 'primereact/avatar';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';
export default function Workpackages() {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [loading, setloading] = useState(false);

    useEffect(() => {
        // Fetch projects from endpoint
        axios.get('/Office/Projects')
            .then(response => {
                setProjects(response.data);
                if (response.data.length > 0) {
                    setSelectedProject(response.data[0]);
                }
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    }, []);

    useEffect(() => {
        if (selectedProject) {
            // Fetch tasks for selected project
            setloading(true);
            axios.get(`/Office/Projects/WorkProjects/${selectedProject.id}`)
                .then(response => {
                    setTasks(response.data);
                    setloading(false);
                })
                .catch(error => {
                    console.error('Error fetching tasks:', error);
                });
        }
    }, [selectedProject]);

    const handleProjectChange = (event) => {
        const projectId = event.target.value;
        const project = projects.find(proj => proj.id === projectId);
        setSelectedProject(project);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredTasks = tasks.filter(task =>
        task.package_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSidebarOpen = (task) => {
        setSelectedTask(task);
        setSidebarVisible(true);
    };

    const idBodyTemplate = (rowData) => {
        return (
            <a href="#" onClick={() => handleSidebarOpen(rowData)}>
                {rowData.id}
            </a>
        );
    };

    const acceptDelete = () => {

    }

    const confirm2 = () => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptDelete
        });
    };


    const infoBodyTemplate = (rowData) => {
        return (
            <Box>
                <IconButton onClick={() => handleSidebarOpen(rowData)}>
                    <InfoIcon />
                </IconButton>
                <IconButton onClick={() => { confirm2() }}>
                    <DeleteRounded />
                </IconButton>
            </Box>
        );
    };

    return (
        <Box display="flex" flexDirection="row" flex="1" width="100%" height="100%" margin="5px">
            <ConfirmDialog />
            <Box height="100%" width="100%" flex="1" position="relative" display='flex' flexDirection='column'>
                <Box display="flex" alignItems="center" marginBottom="10px">
                    <InputLabel id="project-select-label" style={{ marginRight: '10px' }}>Select a Project</InputLabel>
                    <Select
                        labelId="project-select-label"
                        value={selectedProject ? selectedProject.id : ''}
                        onChange={handleProjectChange}
                        style={{ marginRight: '10px' }}
                    >
                        {projects.map((project) => (
                            <MenuItem key={project.id} value={project.id}>
                                {project.project_name}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        label="Search Tasks"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ marginRight: '10px', flex: 1 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleMenuOpen}
                    >
                        Create
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleMenuClose} style={{ color: 'blue' }}>TASK</MenuItem>
                        <MenuItem onClick={handleMenuClose} style={{ color: 'green' }}>MILESTONE</MenuItem>
                        <MenuItem onClick={handleMenuClose} style={{ color: 'orange' }}>PHASE</MenuItem>
                        <MenuItem onClick={handleMenuClose} style={{ color: 'purple' }}>FEATURE</MenuItem>
                        <MenuItem onClick={handleMenuClose} style={{ color: 'red' }}>BUG</MenuItem>
                    </Menu>
                </Box>
                <Box position="relative" width="100%" height="100%">
                    <DataTable value={filteredTasks} rowsPerPageOptions={[5, 10, 20]} scrollable scrollHeight="flex" loading={loading}
                        style={{ top: 0, bottom: 0, position: 'absolute', overflow: 'auto', width: '100%' }}>
                        <Column field="id" header="ID" sortable style={{ width: '90px' }} body={idBodyTemplate}></Column>
                        <Column field="package_name" header="Package Name" sortable ></Column>
                        <Column field="work_type.package_type_name" header="Work Type" sortable style={{ width: '150px' }}
                            body={(rowData) => rowData.work_type.package_type_name}></Column>
                        <Column field="status" header="Status" sortable style={{ width: '150px' }}
                            body={(rowData) => {
                                return (
                                    <p1 style={{ color: 'red' }}>{rowData.status.status_label} </p1>
                                )
                            }} >
                        </Column>
                        <Column field="assigned_persons" header="Assigned Persons" sortable style={{ width: '200px' }}
                            body={(rowData) => rowData.assigned_persons.map(person => person.ro_comment).join(', ')}></Column>
                        <Column field="priority" header="Priority" style={{ width: '100px' }}
                            body={(rowData) => rowData.priority.priority_label} />
                        <Column header="Info" body={infoBodyTemplate} style={{ width: '100px' }}></Column>
                    </DataTable>
                </Box>
            </Box>
            {selectedTask != null ?
                <TaskSidebar visible={sidebarVisible} onHide={() => setSidebarVisible(false)} task={selectedTask} /> : <Box />}
        </Box>
    )
}


function TaskSidebar({ visible, onHide, task }) {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const customHeader = (
        <div className="flex align-items-center gap-2">
            <Tabs flex="1" value={tabValue} onChange={handleTabChange} aria-label="work project tabs">
                <Tab label="Overview" />
                <Tab label="Activity" />
                <Tab label="Files" />
                <Tab label="Relations" />
                <Tab label="Watchers" />
                <Tab label="Release notes" icon={<ArticleIcon />} iconPosition="start" />
            </Tabs>
        </div>
    );

    return (
        <Sidebar visible={visible} onHide={onHide} header={customHeader} style={{ width: '40vw' }}>
            <Box display="flex" flexDirection="column" bottom="0" top="0" height="100%">
                <Box>
                    <Box display="flex" flexDirection="row">
                        <Button
                            variant="contained"
                            color="primary"
                        >
                            {task.work_type.package_type_name}
                        </Button>

                        <Typography variant="h6" gutterBottom>{task.package_name}</Typography>
                    </Box>
                    <Divider style={{ margin: '1rem 0' }} />
                </Box>
                <Box flex="2">
                    <Box >
                        {tabValue === 0 && (
                            <Box>
                                <Box>
                                    <Button
                                        style={{ backgroundColor: task.status.bg_color }}
                                    >{task.status.status_label}</Button>
                                    <p3>Created By:{task.created_by.ro_comment} Last updated at:{task.last_updated}</p3>
                                </Box>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data="<p>Hello from CKEditor&nbsp;5!</p>"
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event) => {
                                        console.log(event);
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                />
                                <Typography variant="h5" gutterBottom>PEOPLE</Typography>
                                <Divider style={{ margin: '1rem 0' }} />
                                <Typography variant="body2">Assignee: -</Typography>
                                <Typography variant="body2">Accountable: -</Typography>
                                <Divider style={{ margin: '1rem 0' }} />
                                <Typography variant="body2" gutterBottom><strong>ESTIMATES AND TIME</strong></Typography>
                                <Typography variant="body2">Work: -</Typography>
                                <Typography variant="body2">Remaining work: -</Typography>
                                <Typography variant="body2">Spent time: 0h</Typography>
                            </Box>
                        )}
                        {tabValue === 1 && <Box>Activity Content</Box>}
                        {tabValue === 2 && <Box>Files Content</Box>}
                        {tabValue === 3 && <Box>Relations Content</Box>}
                        {tabValue === 4 && <Box>Watchers Content</Box>}

                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    borderColor="divider" maxHeight="20px" flex="1">
                    <Button variant="outlined">Unwatch</Button>
                    <Button variant="outlined">Share</Button>
                    <Button variant="outlined">More</Button>
                </Box>
            </Box>
        </Sidebar>
    );
}
