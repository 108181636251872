import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@mui/material';
import '../clickablecell.css';
import { monthsarray } from '../../../common/months';

export default function MonthlyBreakDownDialog({ open, onClose, property, month, year }) {
    const [groupedData, setGroupedData] = useState({});
    const [daysInMonth, setDaysInMonth] = useState([]);

    const fetchData = () => {
        setGroupedData({});
        axios.get(`/Office/Statistics/SalesReport/monthlybreakdown/${property}/${year}/${month}`)
            .then(response => {
                const groupedData = {};

                response.data.forEach(item => {
                    const { listing_name, ota_name, ref_date, price, rent, listing_id } = item;
                    const day = new Date(ref_date).getDate(); // Extract day of the month

                    if (!groupedData[listing_name]) {
                        groupedData[listing_name] = {
                            pid: listing_id
                        }
                    }

                    if (!groupedData[listing_name][ota_name]) {
                        groupedData[listing_name][ota_name] = {};
                    }

                    groupedData[listing_name][ota_name][day] = {
                        price: parseFloat(price.replace(/[^0-9.-]+/g, "")) || 0,
                        rent: parseFloat(rent.replace(/[^0-9.-]+/g, "")) || 0
                    };
                });

                setGroupedData(groupedData);

                // Generate array of days for the selected month
                const daysInMonth = Array.from({ length: new Date(year, month, 0).getDate() }, (_, index) => index + 1);
                setDaysInMonth(daysInMonth);
            })
            .catch(error => {
                console.error('Error fetching sales data:', error);
            });
    };

    useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [open]);

    // Helper function to calculate the total for each OTA and each day
    const calculateTotals = () => {
        const totals = {
            perDay: {},
            perOta: {}
        };

        Object.keys(groupedData).forEach(listing_name => {
            Object.keys(groupedData[listing_name]).filter((item) => item !== 'pid').forEach(ota_name => {
                if (!totals.perOta[listing_name]) {
                    totals.perOta[listing_name] = {};
                }
                if (!totals.perOta[listing_name][ota_name]) {
                    totals.perOta[listing_name][ota_name] = { price: 0, rent: 0 };
                }

                daysInMonth.forEach(day => {
                    const data = groupedData[listing_name][ota_name][day];
                    if (data) {
                        // Accumulate per OTA totals
                        totals.perOta[listing_name][ota_name].price += data.price;
                        totals.perOta[listing_name][ota_name].rent += data.rent;

                        // Accumulate per day totals
                        if (!totals.perDay[day]) {
                            totals.perDay[day] = { price: 0, rent: 0 };
                        }
                        totals.perDay[day].price += data.price;
                        totals.perDay[day].rent += data.rent;
                    }
                });
            });
        });

        return totals;
    };

    const totals = calculateTotals();

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>{monthsarray.find((item) => item.number === parseInt(month ?? 1)).name} - {year} for {property}</DialogTitle>
            <DialogContent style={{ overflowX: 'auto' }}>
                <table className='styled-table' style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={{ position: 'sticky', left: 0, backgroundColor: '#fff', zIndex: 1 }}></th>
                            {daysInMonth.map(day => (
                                <th key={day} colSpan="2" style={{ textAlign: 'center' }}>
                                    {new Date(year, month - 1, day).toLocaleDateString()}
                                </th>
                            ))}
                            <th colSpan="2" style={{ textAlign: 'center' }}>Total</th>
                        </tr>
                        <tr>
                            <th style={{ position: 'sticky', left: 0, backgroundColor: '#fff', zIndex: 1 }}></th>
                            {daysInMonth.map(day => (
                                <>
                                    <th key={`${day}-price`} style={{ textAlign: 'center' }}>Price</th>
                                    <th key={`${day}-rent`} style={{ textAlign: 'center' }}>Rent</th>
                                </>
                            ))}
                            <th style={{ textAlign: 'center' }}>Price</th>
                            <th style={{ textAlign: 'center' }}>Rent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(groupedData).sort().map(listing_name => (
                            <>
                                <tr key={listing_name}>
                                    <td style={{ position: 'sticky', left: 0, backgroundColor: '#f0f0f0', fontWeight: 'bold', zIndex: 1 }}>
                                        {listing_name}
                                    </td>
                                    {daysInMonth.map(day => (
                                        <>
                                            <td style={{ textAlign: 'center' }}>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                            </td>
                                        </>
                                    ))}
                                </tr>
                                {Object.keys(groupedData[listing_name]).filter((item) => item !== 'pid').map(ota_name => (
                                    <tr key={`${listing_name}-${ota_name}`}>
                                        <td style={{ position: 'sticky', left: 0, backgroundColor: '#fff', zIndex: 1 }}>{ota_name}</td>
                                        {daysInMonth.map(day => (
                                            <>
                                                <td key={`${listing_name}-${ota_name}-${day}-price`} style={{ textAlign: 'center' }}>
                                                    {groupedData[listing_name][ota_name][day]?.price || '-'}
                                                </td>
                                                <td key={`${listing_name}-${ota_name}-${day}-rent`} style={{ textAlign: 'center' }}>
                                                    {groupedData[listing_name][ota_name][day]?.rent || '-'}
                                                </td>
                                            </>
                                        ))}
                                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                            {totals.perOta[listing_name][ota_name].price.toFixed(2)}
                                        </td>
                                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                            {totals.perOta[listing_name][ota_name].rent.toFixed(2)}
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ))}
                        <tr>
                            <td style={{ fontWeight: 'bold', position: 'sticky', left: 0, backgroundColor: '#fff', zIndex: 1 }}>Total</td>
                            {daysInMonth.map(day => (
                                <>
                                    <td key={`total-${day}-price`} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                        {totals.perDay[day]?.price.toFixed(2) || '-'}
                                    </td>
                                    <td key={`total-${day}-rent`} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                        {totals.perDay[day]?.rent.toFixed(2) || '-'}
                                    </td>
                                </>
                            ))}
                            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {Object.values(totals.perOta).reduce((acc, otaTotals) => acc + Object.values(otaTotals).reduce((sum, item) => sum + item.price, 0), 0).toFixed(2)}
                            </td>
                            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {Object.values(totals.perOta).reduce((acc, otaTotals) => acc + Object.values(otaTotals).reduce((sum, item) => sum + item.rent, 0), 0).toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

