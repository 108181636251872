import React, { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Divider,
    Select,
    MenuItem,
    FormControl,
    InputLabel, Snackbar, Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { orangeColor } from '../../css/common_sx';
import { searchFunction } from '../../common/search_function';
const CRMStorageItems = () => {
    const [items, setItems] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [selectedStorageItem, setselectedStorageItem] = useState(null);
    const [visibleStorageItemEditor, setVisibleStorageItemEditor] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        axios.get('/Office/CRM/WarehouseItems')
            .then(response => {
                setItems(response.data);
            })
            .catch(error => {
                console.error('Error fetching items:', error);
                setSnackbarMessage('Error fetching items');
                setSnackbarOpen(true);
            });
    }, []);

    const handleStoreItemPopup = (item) => {
        setselectedStorageItem(item);
        setVisibleStorageItemEditor(true);
    };

    const handleCloseStorageItem = (item) => {
        setVisibleStorageItemEditor(false);
        if (item) {
            axios.post('/Office/CRM/WarehouseItems', item)
                .then(res => {
                    if (res.status === 200) {
                        setItems([...items, { ...item, id: items.length + 1 }]);
                    };
                })
                .catch(res => {
                    if (res.status === 409) {
                        setSnackbarMessage('This warehouse item already exists');
                        setSnackbarOpen(true);
                    }
                })
        }
    };

    const headerTemplate = (data) => {
        return (
            <Box>{data.warehouse_name}</Box>
        )
    }

    const filteredItems = searchFunction(globalFilter, ['item_name', 'sku', 'warehouse_name'], items);

    const groupedItems = filteredItems.reduce((acc, item) => {
        (acc[item.warehouse_name] = acc[item.warehouse_name] || []).push(item);
        return acc;
    }, {});

    return (
        <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
                marginBottom={2}
                borderRadius={2}
                boxShadow={3}
                bgcolor={orangeColor} // Adjust the color as needed, or use theme colors like `theme.palette.secondary.light`
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    size="small" // Compact text field
                    sx={{
                        width: '60%', // Adjust width to ensure better spacing 
                        bgcolor: 'white', // White background for contrast
                        borderRadius: 1, // Soft border radius for a clean look
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => handleStoreItemPopup(null)}
                    sx={{
                        paddingLeft: 2,
                        paddingRight: 2,
                        textTransform: 'none', // Remove uppercase to maintain a professional style
                        marginLeft: 2, // Added margin for spacing between input and button
                    }}
                >
                    Add Item
                </Button>
            </Box>
            <Box position="relative" width="100%" height="100%">
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>Item Name</TableCell>
                                <TableCell align='center'>SKU</TableCell>
                                <TableCell align='center'>Cnt</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(groupedItems).map(([warehouseName, warehouseItems]) => (
                                <React.Fragment key={warehouseName}>
                                    {/* Warehouse Header Row */}
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                                            {warehouseName}
                                        </TableCell>
                                    </TableRow>
                                    {/* Items for each warehouse */}
                                    {warehouseItems.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell>{item.item_name}</TableCell>
                                            <TableCell>{item.sku}</TableCell>
                                            <TableCell sx={{ color: (parseInt(item.qty) || 0) > 0 ? 'green' : 'red' }}>
                                                {item.qty}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <StorageItemDialog open={visibleStorageItemEditor} onClose={handleCloseStorageItem} />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="warning" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

const StorageItemDialog = ({ open, onClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [warehouses, setWarehouses] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState("");
    const [selectedItem, setSelectedItem] = useState("");

    useEffect(() => {
        setActiveStep(0);
        if (open) {
            axios.get('/Office/CRM/Warehouses')
                .then(response => {
                    setWarehouses(response.data);
                })
                .catch(error => {
                    console.error('Error fetching warehouses:', error);
                });

            axios.get('/Office/CRM/Items/simple')
                .then(response => {
                    setItems(response.data);
                })
                .catch(error => {
                    console.error('Error fetching items:', error);
                });
        }
    }, [open]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSave = () => {
        const itemData = {
            warehouse: warehouses.find(wh => wh.id === selectedWarehouse).id,
            item: items.find(it => it.id === selectedItem).id
        };
        onClose(itemData);
    };

    const handleClose = () => {
        onClose(null);
    };

    const steps = ['Choose Warehouse', 'Choose Item', 'Summary'];

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Add Item</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === 0 && (
                    <Box>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="warehouse-label">Warehouse</InputLabel>
                            <Select
                                labelId="warehouse-label"
                                value={selectedWarehouse}
                                onChange={(e) => setSelectedWarehouse(e.target.value)}
                            >
                                {warehouses.map(warehouse => (
                                    <MenuItem key={warehouse.id} value={warehouse.id}>
                                        {warehouse.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {activeStep === 1 && (
                    <Box>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="item-label">Item</InputLabel>
                            <Select
                                labelId="item-label"
                                value={selectedItem}
                                onChange={(e) => setSelectedItem(e.target.value)}
                            >
                                {items.map(item => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {`${item.item_name} (${item.sku})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {activeStep === 2 && (
                    <Box>
                        <Typography variant="h6">Summary</Typography>
                        <Divider style={{ margin: '1rem 0' }} />
                        <Typography>Warehouse: {warehouses.find(wh => wh.id === selectedWarehouse)?.name}</Typography>
                        <Typography>Item: {items.find(it => it.id === selectedItem)?.item_name} ({items.find(it => it.id === selectedItem)?.sku})</Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                {activeStep === steps.length - 1 ? (
                    <Button onClick={handleSave} color="primary" variant="contained">Finish</Button>
                ) : (
                    <Button onClick={handleNext} color="primary" variant="contained">Next</Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CRMStorageItems;

