import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Paper } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';

export default function ChatWindow() {
    const [threads, setThreads] = useState([]);
    const [messages, setMessages] = useState([]);
    const [selectedThread, setSelectedThread] = useState(null);
    const [hasMoreThreads, setHasMoreThreads] = useState(true);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [threadPage, setThreadPage] = useState(1);
    const [messagePage, setMessagePage] = useState(1);

    useEffect(() => {
        fetchThreads();
    }, []);

    const fetchThreads = async (page = 1) => {
        try {
            const response = await axios.get(`/Office/Reservations/Chat/threads`, {
                params: { page, limit: 50 }
            });
            const data = response.data;
            if (data.length === 0) {
                setHasMoreThreads(false);
            } else {
                setThreads((prevThreads) => [...prevThreads, ...data]);
                setThreadPage(page + 1);
            }
        } catch (error) {
            console.error('Error fetching threads:', error);
        }
    };

    const fetchMessages = async (threadId, page = 1) => {
        try {
            const response = await axios.get(`/Office/Reservations/Chat/threads/${threadId}/messages`, {
                params: { page, limit: 50 }
            });
            const data = response.data;
            if (data.length === 0) {
                setHasMoreMessages(false);
            } else {
                setMessages((prevMessages) => [...prevMessages, ...data]);
                setMessagePage(page + 1);
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const handleThreadClick = (thread) => {
        setSelectedThread(thread);
        setMessages([]);
        setMessagePage(1);
        setHasMoreMessages(true);
        fetchMessages(thread.id);
    };

    return (
        <Box display="flex" flexDirection="row" flex="1" width="100%" height="100%">
            <Box height="100%"
                flex="1" flexDirection="column"
                display="flex" position='relative' bgcolor={'red'}>
                <InfiniteScroll
                    dataLength={threads.length}
                    next={() => fetchThreads(threadPage)}
                    hasMore={hasMoreThreads}
                    loader={<h4>Loading...</h4>}
                    style={{ position: 'absolute', overflow: 'auto', top: '0', bottom: '0px', left: '0', right: '0' }}
                >
                    <List>
                        {threads.map((thread) => (
                            <ListItem button key={thread.id} onClick={() => handleThreadClick(thread)}>
                                <ListItemText
                                    primary={thread.reservation_id}
                                    secondary={thread.com_ch}
                                />
                            </ListItem>
                        ))}
                    </List>
                </InfiniteScroll>
            </Box>
            <Box display='flex' p={2} flexDirection="column" flex="2">
                {selectedThread ? (
                    <>
                        <Typography variant="h6" gutterBottom>{selectedThread.name}</Typography>
                        <Box
                            flex={1}
                            overflow="auto"
                            display="flex"
                            flexDirection="column-reverse"
                        >
                            <InfiniteScroll
                                dataLength={messages.length}
                                next={() => fetchMessages(selectedThread.id, messagePage)}
                                hasMore={hasMoreMessages}
                                loader={<h4>Loading...</h4>}
                                inverse={true}
                                scrollableTarget="scrollableDiv"
                            >
                                {messages.map((message) => (
                                    <Box
                                        key={message.id}
                                        display="flex"
                                        justifyContent={message.isincome ? 'flex-start' : 'flex-end'}
                                        m={1}
                                    >
                                        <Paper
                                            style={{
                                                padding: '10px',
                                                maxWidth: '60%',
                                                backgroundColor: message.isincome ? '#f1f1f1' : '#1976d2',
                                                color: message.isincome ? 'black' : 'white',
                                            }}
                                        >
                                            <Typography dangerouslySetInnerHTML={{ __html: message.message }} />
                                            <Typography variant="caption" display="block">
                                                {message.sender} - {new Date(message.message_time).toLocaleString()}
                                            </Typography>
                                        </Paper>
                                    </Box>
                                ))}
                            </InfiniteScroll>
                        </Box>
                    </>
                ) : (
                    <Typography>Select a thread to view messages</Typography>
                )}
            </Box>
        </Box>
    );
}

