import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, List, ListItem, Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import TitleBox from './Title';
import axios from 'axios';
import getDateString from './utils';

export default function RecentBookings({ onClick }) {
    const [reservations, setReservations] = useState([]);
    const [refreshTime, setRefreshTime] = useState(new Date().toLocaleTimeString());
    const [error, setError] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const fetchData = async (date) => {
        try {
            const res = await axios.get(`/Office/reservations?type=5&date_from=${getDateString(date)}`);
            if (res.data && res.data.reservations) {
                setReservations(res.data.reservations);
                setError(false);
            } else {
                setError(true);
            }
            setRefreshTime(new Date().toLocaleTimeString());
        } catch (err) {
            setError(true);
        }
    };

    useEffect(() => {
        fetchData(selectedDate);
        const interval = setInterval(() => fetchData(selectedDate), 60000); // Refresh every 60 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, [selectedDate]);

    const handleDateChange = (days) => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + days);
        setSelectedDate(newDate);
    };

    const isRecentBooking = (dateString) => {
        const bookingDate = new Date(dateString);
        const today = new Date();
        bookingDate.setDate(bookingDate.getDate() - 3);
        return today >= bookingDate;
    };

    const totalGuestPrice = reservations.reduce((total, booking) => total + parseFloat(booking.guest_price), 0).toFixed(2);

    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center" mb={2}>
            <TitleBox title="Recent Bookings" refreshTime={refreshTime} error={error} onRefresh={() => fetchData(selectedDate)} />

            <Box
                width="100%"
                height={350}
                bgcolor="lightgoldenrodyellow"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" mb={2}>
                    <IconButton onClick={() => handleDateChange(-1)}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" mx={2}>{getDateString(selectedDate)}</Typography>
                    <IconButton onClick={() => handleDateChange(1)}>
                        <ArrowForward />
                    </IconButton>
                </Box>
                {reservations.length > 0 ? (
                    <Box position="relative" flex="1" width="100%" height="100%">
                        <List
                            sx={{
                                top: '0', bottom: '0', left: '0', right: "0",
                                position: "absolute", overflow: "auto"
                            }}
                        >
                            {reservations.map((booking) => (
                                <ListItem button key={booking.stayid} onClick={() => { onClick(booking.reservation_id) }}>
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{ width: 40, height: 40 }}
                                            src={`https://pcrms.leviathan.systems/${booking.ota_image}`}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={booking.client_name}
                                        secondary={
                                            <>
                                                <Typography variant="body2" color="textSecondary">
                                                    {booking.o_name}
                                                </Typography>
                                                <Typography variant="body2" color={isRecentBooking(booking.date_from) ? 'red' : 'inherit'}>
                                                    {`Date: ${booking.date_from} (${booking.no_of_nights})`}
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <Typography variant="body2" color="textSecondary">
                                        {`Price: ${booking.guest_price}`}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                ) : (
                    <Typography>No recent bookings available.</Typography>
                )}
            </Box>
            <Box width="100%" display="flex" justifyContent="space-between" bgcolor="lightgoldenrodyellow">
                <Typography variant="h6">{`Total Bookings: ${reservations.length}`}</Typography>
                <Typography variant="h6">{`Total Guest Price: ${totalGuestPrice}`}</Typography>
            </Box>
        </Box>
    );
}

