import React, { useState, useEffect } from 'react';
import { Box, IconButton, Tooltip, Collapse, Autocomplete, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import {
  Search as SearchIcon,
  Settings as SettingsIcon,
  Group as FriendsIcon,
  Menu as MenuIcon,
  Money as MoneyIcon, // Example icon import, replace with actual icons as needed
  SleddingSharp
} from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import { BuildMenu, collectMenuItemsWithId } from './dashboard_menu_builder';
import { renderModule } from './dashboard_menu_return';
import { List, ListItem, ListItemText, ListItemIcon, Backdrop, CircularProgress, Typography, Fade, Dialog, DialogContent } from '@mui/material';
import { CommonSX } from './css/common_sx.js';
import Slide from '@mui/material/Slide';
import { HelpdeskIcon } from '@leviathan/clienthelpdesk';
function MainModule({ visible }) {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);
  const [showSideMenu, setShowSideMenu] = useState(true);
  const [availableModules, setAvailableModules] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value) {
      setIsDialogOpen(true);
    }
    //else {
    //    setIsDialogOpen(false);
    //}
  }

  const isMobile = useMediaQuery('(max-width:600px)'); // Detect if screen is smaller than 600px

  const fetchMenu = async () => {
    const menu = await BuildMenu();
    setAvailableModules(menu);
    setMenuItems(menu);
    setSelectedMenuItem(1);
  };

  const handleMenuClick = (item) => {
    if (item.items && item.items.length > 0) {
      setExpandedItem(expandedItem === item ? null : item);
    } else {
      console.log('Navigating to', item.id);
      setSelectedMenuItem(item.id);
      setExpandedItem(null); // Close submenu if navigating to a different top-level item
    }
  };

  const renderSubmenu = (subItems) => (
    <Slide direction="right" in={expandedItem != null} mountOnEnter unmountOnExit>
      <Box width="200px" bgcolor="#F0D58C" padding="8px" zIndex="2" overflow={'auto'} borderRadius={'3px'}>
        <List>
          {subItems.map((subItem) => (
            <React.Fragment key={subItem.id}>
              {subItem.items ? (
                <React.Fragment>
                  <ListItem>
                    <ListItemText
                      primary={subItem.label}
                      primaryTypographyProps={{ fontWeight: 'bold', color: '#0B0C2A' }} // Header styling
                    />
                  </ListItem>
                  <List component="div" disablePadding>
                    {subItem.items.map((nestedItem) => (
                      <ListItem key={nestedItem.id} style={{ paddingLeft: '20px' }} button onClick={() => handleMenuClick(nestedItem)}>
                        <ListItemIcon>
                          {nestedItem.icon || <></>} {/* Render the icon if it exists, otherwise render nothing */}
                        </ListItemIcon>
                        <Tooltip title={nestedItem.label} placement="right">
                          <ListItemText primary={nestedItem.label} />
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </React.Fragment>
              ) : (
                <ListItem button onClick={() => handleMenuClick(subItem)}>
                  <ListItemIcon>
                    {subItem.icon || <></>} {/* Render the icon if it exists, otherwise render nothing */}
                  </ListItemIcon>
                  <Tooltip title={subItem.label} placement="right">
                    <ListItemText primary={subItem.label} />
                  </Tooltip>
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Slide>
  );

  useEffect(() => {
    if (visible) {
      fetchMenu().then(() => setSelectedMenuItem(1));
    }
  }, [visible]);

  useEffect(() => {
    if (isMobile) {
      setShowSideMenu(false); // Hide side menu on mobile
    }
  }, [isMobile]);

  return (
    <Box flex="1" display={visible ? "flex" : "none"} flexDirection="column" width="100vw">
      {/* Top Bar */}
      <Box display="flex" alignItems="center" justifyContent="space-between" padding="8px" bgcolor="#0B0C2A" height="50px">
        {/* Left Spacer */}
        <Box flex="1" >
          <IconButton color="inherit" onClick={() => {
            setShowSideMenu(!showSideMenu);
          }}
          >
            <MenuIcon style={{ color: '#D4AF37' }} />
          </IconButton>
        </Box>

        {/* Centered Search Bar */}
        <Box flex="4" display="flex" justifyContent="center">
          <TextField
            variant="outlined"
            size="small"
            placeholder="Type to search..."
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <SearchIcon style={{ color: '#0B0C2A' }} />
              ),
            }}
            sx={CommonSX}
          />
        </Box>

        {/* Right Side Icons */}
        <Box flex="1" display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton color="inherit">
            <FriendsIcon style={{ color: '#D4AF37' }} />
          </IconButton>
          <IconButton color="inherit">
            <SettingsIcon style={{ color: '#D4AF37' }} />
          </IconButton>
          <Avatar alt="User Avatar" src="/path/to/avatar.jpg" />
        </Box>
      </Box>
      <Box flex="1" bgcolor='#0B0C2A' flexDirection='row' display='flex' >
        <Box display={showSideMenu ? 'flex' : 'none'} flexDirection="column">
          <Box width="50px" flex="1" bgcolor='#D4AF37' display='flex' flexDirection='column' alignItems="center">
            {menuItems.map((item) => (
              <React.Fragment key={item.id}>
                <Tooltip title={item.label} placement="right">
                  <IconButton
                    color="inherit"
                    onClick={() => handleMenuClick(item)}
                    style={{ color: selectedMenuItem === item.id ? '#0B0C2A' : '#0B0C2A' }}
                  >
                    {item.icon}
                  </IconButton>
                </Tooltip>

              </React.Fragment>
            ))}
          </Box>
        </Box>
        {expandedItem && (
          <Box display="flex">
            {renderSubmenu(expandedItem.items)}
          </Box>
        )}
        <Box flex="1" display="flex" flexDirection="column" padding="16px">
          {selectedMenuItem == null ? (
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Please be patient, we are preparing your dashboard...
              </Typography>
            </Backdrop>
          ) : renderModule(selectedMenuItem)}
        </Box>
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        TransitionComponent={Fade}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <Box display="flex" marginTop="16px">
            {/* Left Side: Results List */}
            <Box flex="1" bgcolor="#F0F0F0" padding="8px">
              <List>
                {menuItems
                  .filter(item => item.label.toLowerCase().includes(searchQuery.toLowerCase()))
                  .map(item => (
                    <ListItem
                      key={item.id}
                      button
                      onClick={() => setSelectedResult(item)}
                    >
                      <ListItemText primary={item.label} />
                    </ListItem>
                  ))}
              </List>
            </Box>

            {/* Right Side: Selected Item Description */}
            <Box flex="1" padding="8px">
              {selectedResult ? (
                <Box>
                  <Typography variant="h6">{selectedResult.label}</Typography>
                  <Typography variant="body1">{selectedResult.desc}</Typography>
                </Box>
              ) : (
                <Typography variant="body1">Select an item to see the description</Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box >
  );
}

export default MainModule;

