import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
    Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TablePagination
} from '@mui/material';
import {
    Add as AddIcon
} from '@mui/icons-material';
import axios from 'axios';
import CRMCreateNewDocument from './documents/create_new_document';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { getStatusIcon } from './document_status_icon';
import { searchFunction } from '../../common/search_function';
import CRMViewDocument from './documents/view_document';
import DocumentStatusTypeEditorDialog from './document_type_status_access';
import { debounce } from 'lodash';

export default function CRMDocumentsGrid() {
    const [documents, setDocuments] = useState([]);
    const [globalSearch, setGlobalSearch] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [page, setPage] = useState(0); // Pagination page
    const [rowsPerPage, setRowsPerPage] = useState(30); // 
    const [openCreateDocument, setOpenCreateDocument] = useState(false);
    const [openViewDocument, setOpenViewDocument] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [openDocumentStatusTypeEditor, setOpenDocumentStatusTypeEditor] = useState(false);

    const fetchDocuments = async () => {
        setLoading(true);
        try {
            axios.get('/Office/CRM/Documents').then((response) => {
                setDocuments(response.data);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                setError('Failed to fetch documents');
                setOpenSnackbar(true);
            });
        } catch (error) {
            setError('Failed to fetch documents');
            setOpenSnackbar(true);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const filteredDocuments = useMemo(() => {
        return searchFunction(globalSearch,
            ['document_type_name', 'created_at', 'status', 'source_warehouse_name',
                'destination_warehouse_name', 'partner_reference'], documents)
    }, [globalSearch, documents]);

    // Debounce the search input to avoid filtering on every keystroke
    const handleSearchChange = useCallback(
        debounce((value) => {
            setGlobalSearch(value);
        }, 300), // wait 300ms before applying the search
        []
    );

    useEffect(() => {
        return () => {
            handleSearchChange.cancel();
        };
    }, [handleSearchChange]);

    return (
        <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={2} borderRadius={2} boxShadow={3}
                bgcolor={orangeColor}>
                <TextField
                    label={`Search `}
                    onChange={(e) => handleSearchChange(e.target.value)}
                    fullWidth
                    sx={{ bgcolor: 'white', borderRadius: 1, mx: 2 }}
                />
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpenCreateDocument(true)} >
                    Create New Document
                </Button>
                <Button variant="contained" sx={{ mx: 2 }} color="primary" onClick={() => setOpenDocumentStatusTypeEditor(true)}>
                    Per Type status Access
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{ marginBottom: '10px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Document ID</TableCell>
                            <TableCell align='center'>Document Type</TableCell>
                            <TableCell align='center'>Document Create Date</TableCell>
                            <TableCell align='center'>Document Status</TableCell>
                            <TableCell align='center'>Document Source Warehouse</TableCell>
                            <TableCell align='center'>Document Destination Warehouse</TableCell>
                            <TableCell align='center'>Partner Reference</TableCell>
                            <TableCell align='center'>Order ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? Array.from(new Array(rowsPerPage)).map((_, index) => (
                            <TableRow key={index}>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                            </TableRow>
                        )) : filteredDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((document) =>
                        (
                            <TableRow onClick={() => {
                                setSelectedDocument(document);
                                setOpenViewDocument(true);
                            }} hover
                                style={{ cursor: 'pointer' }} key={document.id}>
                                <TableCell align='center'>{document.document_no}</TableCell>
                                <TableCell align='center'>{document.document_type_name}</TableCell>
                                <TableCell align='center'>{new Date(document.created_at).toLocaleString()}</TableCell>
                                <TableCell align='center'>{getStatusIcon(document.status)} -- {document.status_name}</TableCell>
                                <TableCell align='center'>{document.source_warehouse_name}</TableCell>
                                <TableCell align='center'>{document.destination_warehouse_name}</TableCell>
                                <TableCell align='center'>{document.partner_reference}</TableCell>
                                <TableCell align='center'>N/A</TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[30, 50, 60]}
                component="div"
                sx={CommonSX}
                count={documents.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <CRMCreateNewDocument
                open={openCreateDocument} onClose={() => setOpenCreateDocument(false)} />
            <CRMViewDocument
                open={openViewDocument}
                onClose={(res) => {
                    if (res === 1) {
                        fetchDocuments();
                    }
                    setOpenViewDocument(false);
                }}
                document={selectedDocument}
                onSave={(e) =>
                    setDocuments(prev => {
                        const index = prev.findIndex(x => x.id === e.id);
                        if (index > -1) {
                            prev[index] = e;
                        }
                        return [...prev];
                    })
                }
                onError={(e) => {
                    setError(e);
                    setOpenSnackbar(true);
                }}
            />
            <DocumentStatusTypeEditorDialog open={openDocumentStatusTypeEditor} onClose={() => setOpenDocumentStatusTypeEditor(false)} />
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity="error">{error}</Alert>
            </Snackbar>
        </Box>
    )
};
