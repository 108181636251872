import React, { useState, useEffect, useRef } from "react";
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/fluent-light/theme.css';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';

const SyncProperties = () => {
    const [Properties, setProperties] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedRows, setExpandedRows] = useState([]);

    const [selectedChannel, setSelectedChannel] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const fetchProperties = async () => {
        let response = await axios.get('/StaffGetProperties?hier_id=12&type_id=5')
        console.debug('Properties', response.data);
        setProperties(response.data.MainPropertyList);
    }

    const filteredProperties = Properties.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sync_general = async ({ property_id, command, channel }) => {
        let response = await axios.get(`/Office/Service/CreateService?property_id=${property_id}&command_id=${command}&connection_id=${channel}`)
        console.debug('Sync General ', property_id, response.data);
        const syncStatusMessage = response.data.message;

        // Update sync_status property based on the response
        setProperties(prevProperties => {
            const updatedProperties = [...prevProperties];
            const propertyIndex = updatedProperties.findIndex(prop => prop.property_id === property_id);

            if (propertyIndex !== -1) {
                const ota_index = updatedProperties[propertyIndex].channel_ids.findIndex(prop => prop.connection_id === channel);
                if (ota_index !== -1) {
                    if (command === 101) {
                        updatedProperties[propertyIndex].channel_ids[ota_index].sync__general_status = syncStatusMessage;
                        updatedProperties[propertyIndex].channel_ids[ota_index].sync_general = false;
                    }
                }
            }

            return updatedProperties;
        });
    }
    const sync_properties = async () => {

        try {
            // Loop through all properties
            for (const property of Properties) {
                // Check if sync_general is true
                for (const ota of property.channel_ids) {
                    if (ota.sync_general) {
                        await sync_general({ property_id: property.property_id, command: 101, channel: ota.connection_id });
                    }
                }
                //if (property.sync_general) {
                // Call sync_general function for the current property
                // await sync_general({ property_id: property.property_id });
                //}
            }
        } catch (error) {
            console.error('Error syncing properties:', error);
            // Handle errors if needed
        }
    }
    const toggleRowExpansion = (index) => {
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setExpandedRows([...expandedRows, index]);
        }
    };

    const handleDoubleClick = (channel) => {
        setSelectedChannel(channel);
        setStartDate(channel.startDate || null); // Use the actual property names from your data
        setEndDate(channel.endDate || null); // Use the actual property names from your data
        setDialogVisible(true);
    };

    const handleDialogSubmit = () => {
        // Update the channel with the selected date values
        setProperties(prevProperties => {
            const updatedProperties = prevProperties.map(property =>
                property.property_id === selectedChannel.property_id ?
                    {
                        ...property,
                        channel_ids: property.channel_ids.map(ch =>
                            ch.connection_id === selectedChannel.connection_id ?
                                {
                                    ...ch,
                                    startDate: startDate, // Use the actual property names from your data
                                    endDate: endDate, // Use the actual property names from your data
                                }
                                : ch
                        )
                    }
                    : property
            );
            return updatedProperties;
        });

        // Close the dialog
        setDialogVisible(false);
    };

    useState(() => {
        fetchProperties();
    }, [])
    return (
        <Box display="flex" flexDirection="row" flex="1" width="100%" height="100%" margin="5px">
            <Box flex="5" width="100%" height="100%" display="flex" flexDirection="column">
                <Box justifyContent="start" alignItems="center" display="flex" flexDirection="row">
                    {/*<Typography variant="h6" marginRight="16px">{selectedProperties ? properties.find((item, index) => item.property_id === selectedProperties).name : ''}</Typography>*/}
                    <InputText
                        placeholder="Search by property Name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button label="Submit" onClick={() => sync_properties()} />

                </Box>
                {/*<PaceItem compare_type={selectedCompareType} period_type={selectedPerdiodType} property_id={selectedProperties} /> */}
                <Box position="relative" width="100%" height="100%">
                    <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0" >
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '20%' }}>OTA's</th>
                                    <th>PropertyName</th>
                                    {/*th>Sync General</th>
                                    <th>Sync Status</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProperties.map((item, index) => (
                                    <React.Fragment key={item.property_id}>
                                        <tr>
                                            <td>
                                                {/*<Box flex='1' flexDirection='row' display='flex'>
                                                {item.otas === undefined ? '' : item.otas.map((ota, oindex) => (
                                                    <Box flex='1' key={oindex} style={{ marginRight: '5px', maxHeight: '15px', maxWidth: '16px' }}>
                                                        <img
                                                            src={`https://pcrms.leviathan.systems${ota}`}
                                                            alt={`OTA ${oindex}`}
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>*/}
                                                <Box flex='1' flexDirection='row' display='flex'>
                                                    <Button
                                                        icon={expandedRows.includes(item.property_id) ? "pi pi-chevron-down" : "pi pi-chevron-right"}
                                                        onClick={() => toggleRowExpansion(item.property_id)}
                                                        disabled={item.channel_ids === undefined}
                                                    />
                                                    {item.otas === undefined ? '' : item.otas.map((ota, oindex) => (
                                                        <Box flex='1' key={oindex} style={{ maxHeight: '15px', maxWidth: '16px' }}>
                                                            <img
                                                                src={`https://pcrms.leviathan.systems${ota}`}
                                                                alt={`OTA ${oindex}`}
                                                                style={{ width: '100%', height: '100%' }}
                                                            />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </td>
                                            <td>{item.name}</td>
                                            {/*<td>
                                                <Checkbox checked={item.sync_general == true} onChange={(e) => {
                                                    //console.debug(e);
                                                    // Assuming you want to update the state as well
                                                    setProperties(prevProperties => {
                                                        const updatedProperties = prevProperties.map(property =>
                                                            property.property_id === item.property_id ? { ...property, sync_general: e.checked } : property
                                                        );
                                                        return updatedProperties;
                                                    });
                                                }} />
                                            </td>*/}
                                            <td>{item.sync_status}</td>
                                        </tr>
                                        {expandedRows.includes(item.property_id) && (
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <table style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Connection Name</th>
                                                                    <th>Connection ID</th>
                                                                    <th>Sync General</th>
                                                                    <th>Sync Property Order</th>
                                                                    <th>Sync Calendar</th>
                                                                    <th>Sync Price</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.channel_ids.map((channel, channelIndex) => (
                                                                    <tr key={`channel_${channelIndex}`} style={{ width: '100%' }}>
                                                                        <td>{channel.connection_name}</td>
                                                                        <td>{channel.channel_id === null && channel.sync_general === true ? <b>New</b> : channel.channel_id}</td>
                                                                        <td><Checkbox checked={channel.sync_general === true} onChange={(e) => {
                                                                            const updatedChannels = item.channel_ids.map(ch => (ch.connection_id === channel.connection_id ? { ...ch, sync_general: e.checked } : ch));
                                                                            setProperties(prevProperties => {
                                                                                const updatedProperties = prevProperties.map(property =>
                                                                                    property.property_id === item.property_id ? { ...property, channel_ids: updatedChannels } : property
                                                                                );
                                                                                return updatedProperties;
                                                                            });
                                                                        }} /></td>
                                                                        <td><Checkbox checked={channel.sync_order === true} onChange={(e) => {
                                                                            const updatedChannels = item.channel_ids.map(ch => (ch.connection_id === channel.connection_id ? { ...ch, sync_order: e.checked } : ch));
                                                                            setProperties(prevProperties => {
                                                                                const updatedProperties = prevProperties.map(property =>
                                                                                    property.property_id === item.property_id ? { ...property, channel_ids: updatedChannels } : property
                                                                                );
                                                                                return updatedProperties;
                                                                            });
                                                                        }} disabled={channel.channel_id === null} /></td>
                                                                        <td onDoubleClick={() => handleDoubleClick(channel)}>
                                                                            <Checkbox checked={channel.sync_cal === true} onChange={(e) => {
                                                                                const updatedChannels = item.channel_ids.map(ch => (ch.connection_id === channel.connection_id ? { ...ch, sync_cal: e.checked } : ch));
                                                                                setProperties(prevProperties => {
                                                                                    const updatedProperties = prevProperties.map(property =>
                                                                                        property.property_id === item.property_id ? { ...property, channel_ids: updatedChannels } : property
                                                                                    );
                                                                                    return updatedProperties;
                                                                                });
                                                                            }} disabled={channel.channel_id === null} />
                                                                        </td>
                                                                        <td><Checkbox checked={channel.sync_price === true} onChange={(e) => {
                                                                            const updatedChannels = item.channel_ids.map(ch => (ch.connection_id === channel.connection_id ? { ...ch, sync_price: e.checked } : ch));
                                                                            setProperties(prevProperties => {
                                                                                const updatedProperties = prevProperties.map(property =>
                                                                                    property.property_id === item.property_id ? { ...property, channel_ids: updatedChannels } : property
                                                                                );
                                                                                return updatedProperties;
                                                                            });
                                                                        }} disabled={channel.channel_id === null} /></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </Box>
                </Box>

            </Box >
            <Dialog
                visible={dialogVisible}
                onHide={() => setDialogVisible(false)}
                header="Select Dates"
                modal
                footer={
                    <div>
                        <Button label="Submit" icon="pi pi-check" onClick={handleDialogSubmit} />
                        <Button label="Cancel" icon="pi pi-times" onClick={() => setDialogVisible(false)} />
                    </div>
                }
            >
                <div>
                    <label>Start Date:</label>
                    <Calendar
                        value={startDate}
                        onChange={(e) => setStartDate(e.value)}
                        showTime
                    />
                </div>
                <div>
                    <label>End Date:</label>
                    <Calendar
                        value={endDate}
                        onChange={(e) => setEndDate(e.value)}
                        showTime
                    />
                </div>
            </Dialog>
        </Box >
    )
}

export default SyncProperties;
