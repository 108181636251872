import axios from 'axios';

export async function getSecurityJson() {
    const response = await axios.get('/Office/GetSecurity');
    return response.data;
}

export function isActionEnabled({ json, formName, actionName }) {
    const action = json.find(a => a.form_name === formName && a.action_name === actionName);
    return action ? action.action_enabled : false;
}

export function isActionVisible({ json, formName, actionName }) {
    const action = json.find(a => a.form_name === formName && a.action_name === actionName);
    return action ? action.action_visible : false;
}

