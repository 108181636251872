import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function CleaningCostReport() {
  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%" margin="5px">
      <Box display="flex" flexDirection="row" alignItems="center" mb={1} bgcolor={orangeColor} p={1} borderRadius={1}>
        <Typography marginRight={1} variant="body2">Date From:</Typography>
        <DatePicker
          value={startDate}
          onChange={handleStartDateChange}
          format="yyyy-MM-dd"
          sx={{
            ...CommonSX,
            '& .MuiInputBase-root': { fontSize: '0.875rem', padding: '4px 8px' }
          }}
          renderInput={(params) => <TextField {...params} margin="dense" size="small" />}
        />
        <Typography marginLeft={2} marginRight={1} variant="body2">Date To:</Typography>
        <DatePicker
          value={endDate}
          onChange={handleEndDateChange}
          format="yyyy-MM-dd"
          sx={{
            ...CommonSX,
            '& .MuiInputBase-root': { fontSize: '0.875rem', padding: '4px 8px' }
          }}
          renderInput={(params) => <TextField {...params} margin="dense" size="small" />}
        />
        <TextField
          label="Search by Name"
          variant="outlined"
          size="small"
          value={globalSearch}
          fullWidth
          onChange={(e) => setGlobalSearch(e.target.value)}
          sx={{
            bgcolor: 'white',
            borderRadius: 1
          }}
        />
        <Button
          variant="contained"
          onClick={downloadCsv}
          size="small"
          sx={{ ml: 2, fontSize: '0.75rem', padding: '4px 8px' }}
        >
          Download CSV
        </Button>
      </Box>
    </Box>
  );
}
