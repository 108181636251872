import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Chart from 'react-apexcharts'
import axios from 'axios';
import { ListBox } from 'primereact/listbox';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const ImageItem = ({ id, name, moveImage }) => {
    const [{ isDragging }, drag] = useState({ isDragging: false });

    const source = {
        beginDrag(props) {
            return { id: props.id };
        },
    };

    const target = {
        hover(props, monitor) {
            if (!monitor.isOver({ shallow: true })) {
                return;
            }
            moveImage(props.id, monitor.getClientOffset().x - monitor.getInitialClientOffset().x);
        },
    };

    const collect = (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDropTarget: connect.dropTarget(),
        isDragging: monitor.isDragging(),
    });

    return (
        <DndProvider backend={HTML5Backend}>
            <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }} {...collect.connectDragSource()} {...collect.connectDropTarget()}>
                <img src={name} alt="" className="img-fluid" />
            </div>
        </DndProvider>
    );
};


function ViewClients() {
    const [images, setImages] = useState([
        { id: 1, url: 'https://picsum.photos/id/1/500/300', xPos: 0 },
        { id: 2, url: 'https://picsum.photos/id/2/500/300', xPos: 0 },
    ]);

    const moveImage = (id, deltaX) => {
        const index = images.findIndex((image) => image.id === id);
        const item = images[index];
        const newOrderedItems = [...images];

        // Remove dragged element from array
        newOrderedItems.splice(index, 1);

        // Calculate new position after taking into account mouse movement direction
        let pos = item.xPos + deltaX;
        const middlePosition = window.innerWidth / 2;
        if (pos > middlePosition && Math.abs(middlePosition - pos) >= Math.abs(item.xPos - middlePosition)) {
            pos -= deltaX;
        } else if (pos < middlePosition && Math.abs(middlePosition - pos) <= Math.abs(item.xPos - middlePosition)) {
            pos += deltaX;
        }

        // Insert back at calculated position
        newOrderedItems.splice(newOrderedItems.length - findNearestInsertionPoint(newOrderedItems, pos), 0, item);

        setImages(newOrderedItems);
    };

    function findNearestInsertionPoint(arr, value) {
        for (let i = arr.length - 1; i >= 0; i--) {
            if (value > arr[i].xPos) {
                return i + 1;
            }
        }
        return 0;
    }

    return (
        <div className="container">
            <h1 className="text-center mt-5 mb-5">React Photo Gallery</h1>
            <div className="row">
                {images.map(({ id, url, xPos }) => (
                    <ImageItem key={id} id={id} name={url} moveImage={moveImage} />
                ))}
            </div>
        </div>
    );
}

export default ViewClients
