import React, { useState, useEffect } from 'react';
import { Box, Fade } from '@mui/material'; // Import the Fade component
import ReservationPopUp from './reservations/reservationpopup';
import RecentBookings from './home_modules/recent_bookings';
import Bookings from './home_modules/bookings_graph';
import RecentActivities from './home_modules/recent_activities';
import { MostUsedProperties, PropertiesMostUsedDetail } from './home_modules/most_used_properties';
import getDateString from './home_modules/utils';
import { getSecurityJson } from '../common/securityfunctions';
import { isActionVisible, isActionEnabled } from '../common/securityfunctions';

export default function Home() {
    const [config, setConfig] = useState({
        recentBookings: { visible: false },
        mostUsedProperties: { visible: false },
        recentActivities: { visible: false },
        todaysBookings: { title: "Today's Bookings", dateOffset: 0, bgcolor: "lightblue", visible: false },
        yesterdaysBookings: { title: "Yesterday's Bookings", dateOffset: -1, bgcolor: "lightcoral", visible: false }
    });

    const [reservationShow, setreservationShow] = useState(false);
    const [reservationID, setreservationID] = useState(0);
    const [propertyiesmostuseddetailShow, setpropertyiesmostuseddetailShow] = useState(false);
    const [propertyiesmostuseddetailpropertyid, setpropertyiesmostuseddetailpropertyid] = useState(0);

    const setupModules = async () => {
        const sec_data = await getSecurityJson();
        setConfig(prevConfig => ({
            ...prevConfig,
            recentBookings: { ...prevConfig.recentBookings, visible: isActionEnabled({ json: sec_data.actions, formName: 'tmaindesktopform', actionName: 'actviewreservationdashboard' }) },
            mostUsedProperties: { ...prevConfig.mostUsedProperties, visible: isActionVisible({ json: sec_data.actions, formName: 'tmaindesktopform', actionName: 'actviewreservationdashboard' }) },
            recentActivities: { ...prevConfig.recentActivities, visible: isActionVisible({ json: sec_data.actions, formName: 'tmaindesktopform', actionName: 'actviewreservationdashboard' }) },
            todaysBookings: { ...prevConfig.todaysBookings, visible: isActionVisible({ json: sec_data.actions, formName: 'tmaindesktopform', actionName: 'actviewreservationdashboard' }) },
            yesterdaysBookings: { ...prevConfig.yesterdaysBookings, visible: isActionVisible({ json: sec_data.actions, formName: 'tmaindesktopform', actionName: 'actviewreservationdashboard' }) }
        }));
    };

    useEffect(() => {
        setupModules();
    }, []);

    return (
        <Box display="flex" flexDirection="row" width="100%" height="100vh" flex={1}>
            <Box height="100%" width="100%" flex="1" position="relative">
                <Box position="absolute" display="flex" flexDirection={{ xs: 'column', md: 'row' }} top={0} bottom={0} right={0} left={0} overflow="auto">
                    <Box flex={{ xs: 1, md: 2 }} display="flex" flexDirection="column" mr={{ xs: 0, md: 1 }}>
                        <Fade in={config.recentBookings.visible} timeout={1000}>
                            <Box> {/* Wrap the content inside a Box */}
                                {config.recentBookings.visible && <RecentBookings onClick={(id) => {
                                    setreservationID(id);
                                    setreservationShow(true);
                                }} />}
                            </Box>
                        </Fade>
                        <Fade in={config.mostUsedProperties.visible} timeout={1000}>
                            <Box> {/* Wrap the content inside a Box */}
                                {config.mostUsedProperties.visible && <MostUsedProperties days={30} max={10} selectedProperty={(id) => {
                                    setpropertyiesmostuseddetailpropertyid(id);
                                    setpropertyiesmostuseddetailShow(true);
                                }} />}
                            </Box>
                        </Fade>
                        <Fade in={config.recentActivities.visible} timeout={1000}>
                            <Box> {/* Wrap the content inside a Box */}
                                {config.recentActivities.visible && <RecentActivities />}
                            </Box>
                        </Fade>
                    </Box>
                    <Box flex={{ xs: 1, md: 1 }} display="flex" flexDirection="column" ml={{ xs: 0, md: 1 }}>
                        <Fade in={config.todaysBookings.visible} timeout={1000}>
                            <Box> {/* Wrap the content inside a Box */}
                                {config.todaysBookings.visible && <Bookings title={config.todaysBookings.title} date={getDateString((new Date()), config.todaysBookings.dateOffset)} bgcolor={config.todaysBookings.bgcolor} showTimeOnly={false} enableAutoRefresh={true} />}
                            </Box>
                        </Fade>
                        <Fade in={config.yesterdaysBookings.visible} timeout={1000}>
                            <Box> {/* Wrap the content inside a Box */}
                                {config.yesterdaysBookings.visible && <Bookings title={config.yesterdaysBookings.title} date={getDateString((new Date()), config.yesterdaysBookings.dateOffset)} bgcolor={config.yesterdaysBookings.bgcolor} showTimeOnly={false} enableAutoRefresh={false} />}
                            </Box>
                        </Fade>
                    </Box>
                </Box>
            </Box>
            <ReservationPopUp reservationID={reservationID} visible={reservationShow} onclose={() => setreservationShow(false)} />
            <PropertiesMostUsedDetail propertyid={propertyiesmostuseddetailpropertyid} visible={propertyiesmostuseddetailShow} onclose={() => setpropertyiesmostuseddetailShow(false)} days={30} selectedReservation={(id) => {
                setpropertyiesmostuseddetailShow(false);
                setreservationID(id);
                setreservationShow(true);
            }} />
        </Box>
    );
}

