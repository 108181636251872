import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, List, ListItem, ListItemText, Typography, Divider } from '@mui/material';
import axios from 'axios';

const DocumentEditorDialog = ({ open, onClose }) => {
    const [documentTypes, setDocumentTypes] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [typeAccesses, setTypeAccesses] = useState([]);
    const [changes, setChanges] = useState([]);  // To track changes for confirmation
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    // Add "Void" warehouse
    const voidWarehouse = { id: '-1', name: 'Void' };

    // Fetching data
    useEffect(() => {
        const fetchData = async () => {
            const [docTypes, whs, accesses] = await Promise.all([
                axios.get('/Office/CRM/Documents/types'),
                axios.get('/Office/CRM/Warehouses'),
                axios.get('/Office/CRM/Documents/type_accesses'),
            ]);
            setDocumentTypes(docTypes.data);
            setWarehouses([voidWarehouse, ...whs.data]);  // Insert "Void" warehouse at the beginning
            setTypeAccesses(accesses.data);
        };
        fetchData();
    }, []);

    // Handle toggle of allowed creation for specific source-destination-type
    const handleToggleAllow = (sourceId, destId, docTypeId) => {
        // Prevent toggling if both source and destination are "Void"
        if (sourceId === '-1' && destId === '-1') return;

        const accessIndex = typeAccesses.findIndex(access =>
            access.source_ware === sourceId &&
            access.dst_ware === destId &&
            access.document_type === docTypeId
        );

        let updatedAccesses;
        let newAllowCreate = true;

        if (accessIndex === -1) {
            // If the access doesn't exist, create a new one
            const newAccess = {
                id: `${sourceId}-${destId}-${docTypeId}`, // Placeholder ID
                source_ware: sourceId,
                dst_ware: destId,
                document_type: docTypeId,
                allow_create: true, // By default, allow_create is set to true
            };
            updatedAccesses = [...typeAccesses, newAccess];
        } else {
            // If the access exists, toggle its allow_create field
            updatedAccesses = typeAccesses.map((access, index) => {
                if (index === accessIndex) {
                    newAllowCreate = !access.allow_create;
                    return { ...access, allow_create: newAllowCreate };
                }
                return access;
            });
        }

        setTypeAccesses(updatedAccesses);

        // Add the change to the changes list (without duplicates)
        const newChange = {
            source: sourceId,
            destination: destId,
            documentType: docTypeId,
            allowCreate: newAllowCreate,
        };

        setChanges(prevChanges => {
            const isDuplicate = prevChanges.some(
                (change) =>
                    change.source === newChange.source &&
                    change.destination === newChange.destination &&
                    change.documentType === newChange.documentType
            );
            if (isDuplicate) {
                return prevChanges;
            }
            return [...prevChanges, newChange];
        });
    };

    // Handle save confirmation dialog
    const handleSave = () => {
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmSave = () => {
        setIsConfirmDialogOpen(false);
        // Trigger the actual save logic (API call etc.)
        onClose();  // Close the editor dialog
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
                <DialogTitle>Document Type Access Editor</DialogTitle>
                <DialogContent>
                    {/* The grid/table */}
                    <Table size="small"> {/* Reduced size for a smaller grid */}
                        <TableHead>
                            {/* First header row: Warehouses */}
                            <TableRow>
                                <TableCell sx={{ padding: '4px' }}></TableCell> {/* Static Void Element */}
                                {warehouses.map((wh) => (
                                    <TableCell key={wh.id} colSpan={documentTypes.length} align="center" sx={{ padding: '4px', fontSize: '12px' }}>
                                        {wh.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                            {/* Second header row: Document Types under each Warehouse */}
                            <TableRow>
                                <TableCell sx={{ padding: '4px' }}></TableCell> {/* Static Void Element */}
                                {warehouses.map((wh) => (
                                    documentTypes.map((docType) => (
                                        <TableCell key={`${wh.id}-${docType.id}`} align="center" sx={{ padding: '4px', fontSize: '12px' }}>
                                            {docType.document_name}
                                        </TableCell>
                                    ))
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Table Body: Source Warehouse to Dest Warehouse with Checkboxes */}
                            {warehouses.map((sourceWarehouse) => (
                                <TableRow key={sourceWarehouse.id}>
                                    {/* First column: Source Warehouse */}
                                    <TableCell sx={{ padding: '4px', fontSize: '12px' }}>{sourceWarehouse.name}</TableCell>
                                    {/* Cells for each combination of destination warehouse and document type */}
                                    {warehouses.map((destWarehouse) => (
                                        documentTypes.map((docType) => {
                                            const access = typeAccesses.find(
                                                a => a.source_ware === sourceWarehouse.id &&
                                                    a.dst_ware === destWarehouse.id &&
                                                    a.document_type === docType.id
                                            );
                                            // Disable the checkbox if both source and destination are "Void"
                                            const isVoidCombination = sourceWarehouse.id === '-1' && destWarehouse.id === '-1';
                                            return (
                                                <TableCell key={`${sourceWarehouse.id}-${destWarehouse.id}-${docType.id}`} align="center" sx={{ padding: '2px' }}>
                                                    <Checkbox
                                                        checked={access ? access.allow_create : false}
                                                        onChange={() => handleToggleAllow(sourceWarehouse.id, destWarehouse.id, docType.id)}
                                                        disabled={isVoidCombination}  // Disable checkbox if both source and destination are "Void"
                                                        sx={{ padding: '0', transform: 'scale(0.8)' }} // Smaller checkbox
                                                    />
                                                </TableCell>
                                            );
                                        })
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSave} variant="contained" color="primary">Save Changes</Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Confirm Changes</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">You have made the following changes:</Typography>
                    <List>
                        {changes.map((change, index) => {
                            const sourceName = warehouses.find(w => w.id === change.source)?.name || "Unknown";
                            const destName = warehouses.find(w => w.id === change.destination)?.name || "Unknown";
                            const docTypeName = documentTypes.find(dt => dt.id === change.documentType)?.document_name || "Unknown";
                            const allowCreateStatus = change.allowCreate ? "Allowed" : "Disallowed";
                            return (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={`Source: ${sourceName}, Destination: ${destName}, Document Type: ${docTypeName}`}
                                        secondary={`Creation: ${allowCreateStatus}`}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                    <Divider />
                    <Typography variant="body2" color="textSecondary">
                        Confirm if you want to proceed with these changes.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsConfirmDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleConfirmSave} variant="contained" color="primary">Confirm and Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DocumentEditorDialog;

