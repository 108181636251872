import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  Grid, Card, CardContent, CardActionArea, Menu, MenuItem, LinearProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Modal
} from '@mui/material';
import axios from 'axios';
import { orangeColor, CommonSX } from '../../css/common_sx';
import CRMOrderDetails from './order_details';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';

export default function CRMOrders() {
  const [globalFilter, setGlobalFilter] = useState('');
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(30); // Rows per page
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const fetchOrders = async () => {
    setLoading(true);
    try {
      axios.get('/Office/CRM/Orders')
        .then((response) => {
          setOrders(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError('Failed to fetch orders');
          setOpenSnackbar(true);
        });
    } catch (error) {
      setError('Failed to fetch orders');
      setOpenSnackbar(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const filteredOrders = orders.filter(order =>
    ['order_id', 'order_person', 'warehouse_name']
      .some((key) => (order[key] || '').toString().toLowerCase().includes(globalFilter.toLowerCase())));

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={2} borderRadius={2} boxShadow={3} bgcolor={orangeColor}>
        <TextField
          label={`Search`}
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          fullWidth
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 2 }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />} >
          Create New Order
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginBottom: '10px' }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Order ID</TableCell>
              <TableCell align='center'>Order Type</TableCell>
              <TableCell align='center'>Order Status</TableCell>
              <TableCell align='center'>Order Priority</TableCell>
              <TableCell align='center'>Order Date (Per Local)</TableCell>
              <TableCell align='center'>Order Warehouse</TableCell>
              <TableCell align='center'>Customer</TableCell>
              <TableCell align='center'>Order Fulfill Date</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ?
              Array.from(new Array(rowsPerPage)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell><Skeleton variant="text" /></TableCell>
                  <TableCell><Skeleton variant="text" /></TableCell>
                  <TableCell><Skeleton variant="text" /></TableCell>
                  <TableCell><Skeleton variant="text" /></TableCell>
                  <TableCell><Skeleton variant="text" /></TableCell>
                  <TableCell><Skeleton variant="text" /></TableCell>
                  <TableCell><Skeleton variant="text" /></TableCell>
                  <TableCell><Skeleton variant="text" /></TableCell>
                </TableRow>
              ))
              :
              filteredOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.order_id}</TableCell>
                  <TableCell>{order.order_type}</TableCell>
                  <TableCell>{order.order_status}</TableCell>
                  <TableCell>N/A</TableCell>
                  <TableCell>{new Date(order.created_at).toLocaleString()}</TableCell>
                  <TableCell>{order.warehouse_name}</TableCell>
                  <TableCell>{order.order_person}</TableCell>
                  <TableCell>{order.order_fill_date ? new Date(order.order_fill_date).toLocaleString() : ''}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => {
                      setSelectedOrder(order.id);
                      setOpenOrderDetails(true);
                    }} ><InfoIcon fontSize='small' /></IconButton>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <CRMOrderDetails orderID={selectedOrder} open={openOrderDetails} onClose={() => setOpenOrderDetails(false)} />
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        component="div"
        sx={CommonSX}
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">{error}</Alert>
      </Snackbar>
    </Box>
  )
};
