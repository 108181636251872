import React, { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Snackbar, Alert
} from '@mui/material';

import ItemsDialog from '../pick_item';
import ItemsTable from './items_table';
import CRMWarehousesDialog from '../pick_warehouse';
import { orangeColor } from '../../../css/common_sx';
import LoadingBackdrop from '../../../common/loading_backdrop';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';

export default function CRMCreateNewDocument({ open, onClose }) {
    const [showItemsDialog, setShowItemsDialog] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showWarehousesDialog, setShowWarehousesDialog] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState({
        deliveryFrom: null,
        deliveryTo: null,
    });
    const [warehouseType, setWarehouseType] = useState(''); // Track which warehouse (From/To) is being selected
    const [documentTypes, setDocumentTypes] = useState([]); // To store document types from API
    const [selectedDocumentType, setSelectedDocumentType] = useState(''); // To store selected document type
    const [requireAmount, setRequireAmount] = useState(false); // To track if amount is required for the selected type
    const [allowSrcWarehouse, setAllowSrcWarehouse] = useState(false); // To track if source warehouse is allowed for the selected
    const [allowDestWarehouse, setAllowDestWarehouse] = useState(false); // To track if destination warehouse is allowed for the selected
    const [requiredBalance, setRequiredBalance] = useState(false); // To store the required balance for the selected document type
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);


    useEffect(() => {
        axios.get('/Office/CRM/Documents/types')
            .then((response) => {
                setDocumentTypes(response.data); // Assuming the data is an array of objects { id, document_name, require_amount }
                setSelectedDocumentType(response.data[0]?.id || ''); // Select the first document type by default
            })
            .catch((error) => {
                console.error('Error fetching document types:', error);
            });
    }, [open]);

    const handleDocumentTypeChange = (e) => {
        setSelectedDocumentType(e.target.value);
    };

    useEffect(() => {
        const selectedType = documentTypes.find(type => type.id === selectedDocumentType)
        setRequireAmount(selectedType?.require_amount || false);
        setAllowSrcWarehouse(selectedType?.allow_src || false);
        setAllowDestWarehouse(selectedType?.allow_dst || false);
        setSelectedWarehouse({ deliveryFrom: null, deliveryTo: null });
        setRequiredBalance(selectedType?.require_balance || false);
    }, [selectedDocumentType]);

    const handleAddItem = (item) => {
        if (item) {
            // Add quantity, gross, net, vat, and amount fields for each item
            setSelectedItems((prevItems) => [...prevItems, { ...item, quantity: 1, gross: '', net: '', vat: '', amount: '' }]);
        }
        setShowItemsDialog(false);
    };

    const handleIncrementQuantity = (index) => {
        setSelectedItems((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? { ...item, quantity: item.quantity + 1 } : item
            )
        );
    };

    const handleDecrementQuantity = (index) => {
        setSelectedItems((prevItems) =>
            prevItems.map((item, i) =>
                i === index && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
            )
        );
    };

    const handleFieldChange = (index, field, value) => {
        setSelectedItems((prevItems) =>
            prevItems.map((item, i) => {
                if (i === index) {
                    // Update the field that was changed
                    const updatedItem = { ...item, [field]: value };

                    // If requireAmount is true, calculate gross and total
                    if (requireAmount) {
                        const net = parseFloat(updatedItem.net || 0);
                        const vat = parseFloat(updatedItem.vat || 0);
                        const quantity = parseFloat(updatedItem.quantity || 0);

                        updatedItem.gross = net + vat;  // Calculate gross
                        updatedItem.total = updatedItem.gross * quantity;  // Calculate total
                    }

                    return updatedItem;
                }
                return item;
            })
        );
    };

    const handleSave = async () => {
        // Ensure every item has valid amount-related fields if required
        setSaving(false);
        if (allowSrcWarehouse && !selectedWarehouse.deliveryFrom) {
            alert('Please select a source warehouse.');
            return;
        }
        if (allowDestWarehouse && !selectedWarehouse.deliveryTo) {
            alert('Please select a destination warehouse.');
        }
        if (requireAmount) {
            const invalidItems = selectedItems.some(item =>
                !item.gross || !item.net || !item.vat || item.quantity == 0
            );
            console.log('Invalid items:', invalidItems);
            if (invalidItems) {
                alert('Please enter all required fields (Gross, Net, VAT, and Quantity) for all items.');
                return;
            }

        }
        setSaving(true);
        try {
            const document_id = await axios.post('/Office/CRM/Documents', {
                document_type_id: selectedDocumentType,
                source_warehouse: selectedWarehouse.deliveryFrom !== null ? selectedWarehouse.deliveryFrom.id : -1,
                destination_warehouse: selectedWarehouse.deliveryTo !== null ? selectedWarehouse.deliveryTo.id : -1,
            });
            console.log('Document created:', document_id);
            let doc_items = [];
            for (const item of selectedItems) {
                if (allowSrcWarehouse) {
                    doc_items.push({
                        item_id: item.id,
                        item_qty: -item.quantity,
                        warehouse: selectedWarehouse.deliveryFrom.id,
                    });
                }
                if (allowDestWarehouse) {
                    if (requireAmount) {
                        doc_items.push({
                            item_id: item.id,
                            item_qty: item.quantity,
                            warehouse: selectedWarehouse.deliveryTo.id,
                            unit_gross_value: item.gross,
                            unit_net_value: item.net,
                            unit_vat_value: item.vat,
                        });
                    }
                    else {
                        doc_items.push({
                            item_id: item.id,
                            item_qty: item.quantity,
                            warehouse: selectedWarehouse.deliveryTo.id,
                        });
                    }
                }
            }
            axios.post(`/Office/CRM/DocumentItems/${document_id.data[0].id}?create_store_items=true`, doc_items)
                .then((response) => {
                    if (response.status === 200) {
                        setSaving(false);
                        onClose(1);
                    }
                }).catch((error) => {
                    //console.error('Error saving document:', error);
                    setError('Failed to save document');
                    setOpenSnackbar(true);
                    setSaving(false);
                });
        } catch (error) {
            console.error('Error saving document:', error);
            setSaving(false);
            setError('Failed to save document');
            setOpenSnackbar(true);
        }
        setSaving(false);
    };

    const handleWarehouseSelection = (warehouse) => {
        setSelectedWarehouse((prev) => ({
            ...prev,
            [warehouseType]: warehouse,
        }));
        setShowWarehousesDialog(false);
    };

    const openWarehouseDialog = (type) => {
        setWarehouseType(type);
        setShowWarehousesDialog(true);
    };

    const handleRemoveItem = (index) => {
        setSelectedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };

    const handleClose = () => {
        if (!saving) {
            onClose();
        }
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle bgcolor={orangeColor}>Create New Document</DialogTitle>
            <DialogContent>
                <Grid container spacing={3} mt={2}>
                    {/* Document Type Selection */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Document Type</InputLabel>
                            <Select
                                value={selectedDocumentType}
                                onChange={handleDocumentTypeChange}
                            >
                                {documentTypes.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.document_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} key={'deliveryFrom'}>
                        <Box display="flex" alignItems="center" marginY={2}>
                            <Box>
                                <Typography variant="body1">
                                    Delivery From:
                                </Typography>
                                <Typography variant="body2">
                                    {selectedWarehouse['deliveryFrom']?.name || 'No selection'}
                                </Typography>
                                <Typography variant="body2">
                                    {selectedWarehouse['deliveryFrom']?.full_address || ''}
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!allowSrcWarehouse}
                                onClick={() => openWarehouseDialog('deliveryFrom')}
                                style={{ marginLeft: 10 }}
                            >
                                {'deliveryFrom' === 'deliveryFrom' ? 'Choose Warehouse' : 'Choose Client'}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6} key={'deliveryTo'}>
                        <Box display="flex" alignItems="center" marginY={2}>
                            <Box>
                                <Typography variant="body1">
                                    Delivery To:
                                </Typography>
                                <Typography variant="body2">
                                    {selectedWarehouse['deliveryTo']?.name || 'No selection'}
                                </Typography>
                                <Typography variant="body2">
                                    {selectedWarehouse['deliveryTo']?.full_address || ''}
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!allowDestWarehouse}
                                onClick={() => openWarehouseDialog('deliveryTo')}
                                style={{ marginLeft: 10 }}
                            >
                                {'deliveryTo' === 'deliveryFrom' ? 'Choose Warehouse' : 'Choose Client'}
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField label="Description" fullWidth multiline rows={4} variant="outlined" />
                    </Grid>

                    {/* Items table section */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Items</Typography>
                        <ItemsTable
                            items={selectedItems}
                            onIncrement={handleIncrementQuantity}
                            onDecrement={handleDecrementQuantity}
                            onFieldChange={handleFieldChange}
                            onRemove={handleRemoveItem}
                            requireAmount={requireAmount}
                        />
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => setShowItemsDialog(true)}
                            startIcon={<AddIcon />}
                            style={{ marginTop: '20px' }}
                        >
                            Add New Item
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
            <LoadingBackdrop open={saving} text={"Creating new document"} />
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity="error">{error}</Alert>
            </Snackbar>
            {/* Dialogs for selecting items and warehouses */}
            <ItemsDialog open={showItemsDialog} onClose={handleAddItem} />
            <CRMWarehousesDialog open={showWarehousesDialog} onClose={handleWarehouseSelection} />
        </Dialog>
    );
}

