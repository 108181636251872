
import CRMWarehouses from './screens/crm/warehouses';
import ReservationsDashboard from './screens/reservations/ViewReservations';
import ChatWindow from './screens/clients/chat';
import PropertyViewer from './screens/properties/viewproperties';
import AllSalesReport from './screens/statistics/allsales';
import RentalsUnitedCMManager from './screens/services/rentals_untied_cm_manager';
import CRMPhysicalItems from './screens/crm/physical_items';
import Workpackages from './screens/tasks/projects/workpackages';
import ZReport from './screens/statistics/zreport';
import CRMItems from './screens/crm/items';
import CRMStorageItems from './screens/crm/storage_items';
import CRMDocuments from './screens/crm/documents';
import CheckoutSchedule from './screens/schedules/checkouts';
import PaceRep from './screens/statistics/pacerep';
import SalesRep from './screens/statistics/salesrep';
import CleaningSchedule from './screens/schedules/cleaning';
import THistoryViewer from './screens/Logging/thistoryviewer';
import AppLog from './screens/applogs.jsx';
import UserManagement from './screens/hr/usersmanagement';
import UserAccessControllManagement from './screens/hr/useraccesscontroll';
import MonitoringView from './screens/monitoring/view';
import PropertiesList from './screens/propertieslist';
import ViewClients from './screens/clients/viewclients';
import SyncProperties from './screens/services/syncproperties';
import Calendar from './screens/reservations/calendarview';
import ManageProjects from './screens/tasks/projects/manageprojects';
import CRMOrders from './screens/crm/orders';
import CRMDocumentsGrid from './screens/crm/documents_grid';
import Home from './screens/home';
import Typography from '@mui/material/Typography';
import CleaningDefaultTypePriceEditor from './screens/schedules/default_type_price_editor';
import CleaningCostReport from './screens/statistics/cleaningReport';
export function renderModule(selectedMenuItem) {
  switch (selectedMenuItem) {
    case 1:
      return <Home />;
    case 11:
      return <SalesRep />;
    case 12:
      return <PaceRep />;
    case 13:
      return <ZReport />;
    case 14:
      return <AllSalesReport />;
    case 15:
      return <CleaningCostReport />;
    case 101:
      return <AppLog />;
    case 201:
      return <CleaningSchedule />;
    case 202:
      return <CheckoutSchedule />;
    case 203:
      return <CleaningDefaultTypePriceEditor />;
    case 301:
      return <PropertiesList />;
    case 401:
      return <PropertyViewer />;
    case 402:
      return <ChatWindow />;
    case 501:
      return <ReservationsDashboard />;
    case 1091:
      return <THistoryViewer key={'staysearch'} postfixqry={'staysearch'} />;
    case 1092:
      return <THistoryViewer key={'ressearch'} postfixqry={'reaservationsearch'} />;
    case 1093:
      return <THistoryViewer key={'propertyprice'} postfixqry={'propertiesprices'} />;
    case 2001:
      return <ManageProjects />;
    case 2002:
      return <Workpackages />;
    case 3001:
      return <CRMItems />;
    case 3002:
      return <CRMStorageItems />;
    case 3003:
      return <CRMDocuments />;
    case 3004:
      return <CRMPhysicalItems />;
    case 3005:
      return <CRMWarehouses />;
    case 3006:
      return <CRMOrders />;
    case 3007:
      return <CRMDocumentsGrid />;
    case 9001:
      return <MonitoringView />;
    case 9002:
      return <UserAccessControllManagement />;
    case 9101:
      return <SyncProperties />;
    case 9102:
      return <RentalsUnitedCMManager />;
    default:
      return <Typography color='white'>Unhandled menu item {selectedMenuItem}</Typography>;
  }
};
