import {
    Add as AddIcon, Close as CloseIcon, CheckCircle as CheckCircleIcon,
    LocalShipping as LocalShippingIcon, Inventory2 as Inventory2Icon,
    MoveToInbox as MoveToInboxIcon, AssignmentTurnedIn as AssignmentTurnedInIcon,
    ShoppingCart as ShoppingCartIcon, Feed as FeedIcon, MoreVert, EventNote, Person, Warehouse
} from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InventoryIcon from '@mui/icons-material/Inventory';
import HistoryIcon from '@mui/icons-material/History';
export const getStatusIcon = (status) => {
    switch (status) {
        case 0: return <CheckCircleIcon color="green" />;
        case 70: return <InventoryIcon color="info" />;
        case 60: return <InventoryIcon color="disabled" />;
        case 80: return <HistoryIcon color="disabled" />;
        case 99: return <AddCircleOutlineIcon color="error" />;
        default: return <CheckCircleIcon color="disabled" />;
    }
};
