import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    IconButton,
    TextField,
    Grid
} from '@mui/material';
import { DataTable } from 'primereact/datatable';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import axios from 'axios';
const getCurrentVersion = (versions) => {
    if (!versions || versions.length === 0) {
        return "No versions";
    }
    const latestVersion = versions.reduce((latest, version) => {
        return (new Date(version.created_at) > new Date(latest.created_at)) ? version : latest;
    });
    return `${latestVersion.version_major}.${latestVersion.version_minor}.${latestVersion.version_patch}`;
};

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

export default function ManageProjects() {
    const [projects, setprojects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const fetchData = async () => {
        let response = await axios.get('/Office/Projects');
        //console.debug("Projects:", response.data);
        setprojects(response.data);

    }



    useEffect(() => {
        fetchData();
    }, []);
    const actionBodyTemplate = (rowData) => {
        return <Button type="button" icon="pi pi-cog" rounded onClick={() => handleButtonClick(rowData)}></Button>;
    };

    const handleButtonClick = (project) => {
        setSelectedProject(project);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedProject(null);
        setTabIndex(0);
    };

    const [isAddVersionDialogOpen, setIsAddVersionDialogOpen] = useState(false);
    const [newVersion, setNewVersion] = useState({ major: '', minor: '', patch: '', description: '' });

    const handleOpenAddVersionDialog = () => {
        setIsAddVersionDialogOpen(true);
    };

    const handleCloseAddVersionDialog = () => {
        setIsAddVersionDialogOpen(false);
        setNewVersion({ major: '', minor: '', patch: '', description: '' });
    };

    const handleAddVersion = () => {
        console.log("New version added:", newVersion);
        // Implement your add version logic here
        handleCloseAddVersionDialog();
    };

    const [isAddVcsDialogOpen, setIsAddVcsDialogOpen] = useState(false);
    const [newVcs, setNewVcs] = useState({ name: '', url: '' });

    const handleOpenAddVcsDialog = () => {
        setIsAddVcsDialogOpen(true);
    };

    const handleCloseAddVcsDialog = () => {
        setIsAddVcsDialogOpen(false);
        setNewVcs({ name: '', url: '' });
    };

    const handleAddVcs = () => {
        console.log("New VCS added:", newVcs);
        //setVcsList([...vcsList, newVcs]);
        handleCloseAddVcsDialog();
    };

    return (
        <Box display="flex" flexDirection="row" flex="1" width="100%" height="100vh" >
            <Box height="100%" width="100%" flex="1" position="relative">
                <DataTable
                    value={projects}
                    scrollable
                    scrollHeight="flex"
                    style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}>
                    <Column field='id' header="ID" />
                    <Column field='project_name' header="Name" />
                    <Column field='versions' header="Current Version" body={rowData => {
                        return getCurrentVersion(rowData.versions);
                    }} />
                    <Column header=""
                        bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                        body={actionBodyTemplate} />
                </DataTable>
            </Box>
            {selectedProject && (
                <ProjectDialog
                    project={selectedProject}
                    isOpen={isDialogOpen}
                    onClose={handleCloseDialog}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    onAddVersionClick={handleOpenAddVersionDialog}
                    onAddVCSClick={handleOpenAddVcsDialog}
                />
            )}
            <AddVersionDialog
                isOpen={isAddVersionDialogOpen}
                onClose={handleCloseAddVersionDialog}
                onAdd={handleAddVersion}
                newVersion={newVersion}
                setNewVersion={setNewVersion}
            />
            <AddVcsDialog
                isOpen={isAddVcsDialogOpen}
                onClose={handleCloseAddVcsDialog}
                onAdd={handleAddVcs}
                newVcs={newVcs}
                setNewVcs={setNewVcs}
            />
        </Box>
    )
}

function ProjectDialog({ project, isOpen, onClose, tabIndex, setTabIndex, onAddVersionClick, onAddVCSClick }) {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth PaperProps={{
            style: {
                width: '90vw',
                height: '70vh'
            }
        }}>
            <DialogTitle>Project Details of {project.project_name}</DialogTitle>
            <DialogContent>
                <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
                    <Tab label="General" />
                    <Tab label="Access" />
                    <Tab label="Versions" />
                    <Tab label="VCS" />
                </Tabs>
                <Box display="flex" flexDirection="row" flex="1" width="100%" height="90%">
                    <Box height="100%" width="100%" flex="1" position="relative">
                        {tabIndex === 0 && (
                            <Box p={3}>
                                <Typography variant="h6">General</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Project Name"
                                            value={project.project_name}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Created By"
                                            value={project.created_by}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Created At"
                                            value={formatDate(project.created_at)}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Last Version"
                                            value={getCurrentVersion(project.versions)}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        {tabIndex === 1 && (
                            <Box p={3}>
                                <Typography variant="h6">Access</Typography>
                                {/* Add access information here */}
                            </Box>
                        )}
                        {tabIndex === 2 && (
                            <Box p={3} position="absolute" top="0" bottom="0" left="0" right="0" overflow="auto">
                                <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="h6">Versions</Typography>
                                    <IconButton onClick={onAddVersionClick}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <List>
                                        {project.versions.map(version => (
                                            <ListItem key={version.id} secondaryAction={
                                                <Button variant="contained" onClick={() => { }}>
                                                    Settings
                                                </Button>
                                            }>
                                                <ListItemText
                                                    primary={`Version: ${version.version_major}.${version.version_minor}.${version.version_patch}`}
                                                    secondary={`Created at: ${version.created_at}`}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        )}
                        {tabIndex === 3 && (
                            <Box p={3} position="absolute" top="0" bottom="0" left="0" right="0" overflow="auto">
                                <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="h6">VCS</Typography>
                                    <IconButton onClick={onAddVCSClick}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <List>
                                        {project.vcss.map((vcs, index) => (
                                            <ListItem key={index} secondaryAction={
                                                <Button variant="contained" onClick={() => { /* Handle VCS settings */ }}>
                                                    <GitHubIcon /> {/* Add the Git icon here */}
                                                    Settings
                                                </Button>
                                            }>
                                                <ListItemText
                                                    primary={`VCS: ${vcs.vcs_name}`}
                                                    secondary={`URL: ${vcs.clone_url}`}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog >
    );
}

function AddVersionDialog({ isOpen, onClose, onAdd, newVersion, setNewVersion }) {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewVersion(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Add New Version</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" p={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                label="Major"
                                name="major"
                                type="number"
                                fullWidth
                                value={newVersion.major}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Minor"
                                name="minor"
                                type="number"
                                fullWidth
                                value={newVersion.minor}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Patch"
                                name="patch"
                                type="number"
                                fullWidth
                                value={newVersion.patch}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                name="description"
                                type="text"
                                fullWidth
                                value={newVersion.description}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button onClick={onAdd} color="primary">Add</Button>
            </DialogActions>
        </Dialog>
    );
}

function AddVcsDialog({ isOpen, onClose, onAdd, newVcs, setNewVcs }) {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewVcs(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add New VCS</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="VCS Name"
                                name="name"
                                value={newVcs.name}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="VCS URL"
                                name="url"
                                value={newVcs.url}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onAdd} variant="contained" color="primary">Add</Button>
            </DialogActions>
        </Dialog>
    );
}
