export const orangeColor = '#D4AF37';

export const CommonSX = {
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: orangeColor
        },
        '&:hover fieldset': {
            borderColor: orangeColor
        },
        '&.Mui-focused fieldset': {
            borderColor: orangeColor
        },
    },
    backgroundColor: orangeColor,
    borderRadius: '4px',
};
