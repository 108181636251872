import { Backdrop, CircularProgress, Typography } from '@mui/material';

export default function LoadingBackdrop({ open, text }) {
    return (

        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            open={open}
        >
            <CircularProgress color="inherit" />
            <Typography variant="h6" sx={{ mt: 2 }}>
                {text || 'Loading'}
            </Typography>
        </Backdrop>

    );
}

