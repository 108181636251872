import React, { useState, useEffect } from "react";
import { Box, Typography, Breadcrumbs, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton } from '@mui/material';
import axios from 'axios';

const RentalsUnitedCMManager = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [clusters, setClusters] = useState([]);
    const [selectedCluster, setSelectedCluster] = useState('');
    const [units, setUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState('');
    const [properties, setProperties] = useState([]);
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchClusters = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/Office/OTA/RentalsUnited/clusters');
            setClusters(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching clusters:", error);
            setLoading(false);
        }
    };

    const fetchUnits = async (clusterId) => {
        try {
            setLoading(true);
            const response = await axios.get(`/Office/OTA/RentalsUnited/clusters/${clusterId}/units`);
            setUnits(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching units:", error);
            setLoading(false);
        }
    };

    const fetchProperties = async (unitId) => {
        try {
            setLoading(true);
            const response = await axios.get(`/Office/OTA/RentalsUnited/units/${unitId}/properties`);
            setProperties(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching properties:", error);
            setLoading(false);
        }
    };

    const fetchRates = async (unitId) => {
        try {
            setLoading(true);
            const response = await axios.get(`/Office/OTA/RentalsUnited/units/${unitId}/rates`);
            setRates(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching rates:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClusters();
    }, []);

    const handleClusterSelect = (clusterId) => {
        setSelectedCluster(clusterId);
        setUnits([]);
        setProperties([]);
        setRates([]);
        setCurrentStep(1);
        fetchUnits(clusterId);
    };

    const handleUnitSelect = (unitId) => {
        setSelectedUnit(unitId);
        setProperties([]);
        setRates([]);
        setCurrentStep(2);
        fetchProperties(unitId);
        fetchRates(unitId);
    };

    const handleBreadcrumbClick = (step) => {
        setCurrentStep(step);
    };

    return (
        <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%" margin="5px" overflow="auto">
            <Typography variant="h4" gutterBottom>
                Rentals United CM Manager
            </Typography>

            <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '20px' }}>
                <Link color="inherit" onClick={() => handleBreadcrumbClick(0)} style={{ cursor: 'pointer' }}>
                    Clusters
                </Link>
                {currentStep > 0 && (
                    <Link color="inherit" onClick={() => handleBreadcrumbClick(1)} style={{ cursor: 'pointer' }}>
                        Units
                    </Link>
                )}
                {currentStep > 1 && (
                    <Typography color="textPrimary">Properties & Rates</Typography>
                )}
            </Breadcrumbs>

            {loading ? (
                <Skeleton variant="rectangular" width="100%" height={118} />
            ) : (
                <>
                    {currentStep === 0 && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Clusters
                            </Typography>
                            <TableContainer component={Paper} style={{ flex: 1, marginBottom: '20px', overflow: 'auto', maxHeight: 'calc(100vh - 300px)' }}>
                                <Table size="small" aria-label="clusters table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Cluster ID</TableCell>
                                            <TableCell>Active</TableCell>
                                            <TableCell>Agent Name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {clusters.map(cluster => (
                                            <TableRow key={cluster.cluster_id} onClick={() => handleClusterSelect(cluster.cluster_id)} style={{ cursor: 'pointer' }}>
                                                <TableCell>{cluster.cluster_id}</TableCell>
                                                <TableCell>{cluster.cluster_active ? 'Yes' : 'No'}</TableCell>
                                                <TableCell>{cluster.company_name}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}

                    {currentStep === 1 && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Units
                            </Typography>
                            <TableContainer component={Paper} style={{ flex: 1, marginBottom: '20px', overflow: 'auto', maxHeight: 'calc(100vh - 300px)' }}>
                                <Table size="small" aria-label="units table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Unit ID</TableCell>
                                            <TableCell>Active</TableCell>
                                            <TableCell>Content Status</TableCell>
                                            <TableCell>ARI Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {units.map(unit => (
                                            <TableRow key={unit.unit_id} onClick={() => handleUnitSelect(unit.unit_id)} style={{ cursor: 'pointer' }}>
                                                <TableCell>{unit.unit_id}</TableCell>
                                                <TableCell>{unit.unit_active ? 'Yes' : 'No'}</TableCell>
                                                <TableCell>{unit.unit_content_status}</TableCell>
                                                <TableCell>{unit.unit_ari_status}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}

                    {currentStep === 2 && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Properties
                            </Typography>
                            <TableContainer component={Paper} style={{ flex: 1, marginBottom: '20px', overflow: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                                <Table size="small" aria-label="properties table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Property ID</TableCell>
                                            <TableCell>PUID</TableCell>
                                            <TableCell>Internal Name</TableCell>
                                            <TableCell>Name Rental</TableCell>
                                            <TableCell>Unit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {properties.map(property => (
                                            <TableRow key={property.property_id}>
                                                <TableCell>{property.property_id}</TableCell>
                                                <TableCell>{property.puid}</TableCell>
                                                <TableCell>{property.name}</TableCell>
                                                <TableCell>{property.name_rental}</TableCell>
                                                <TableCell>{property.unit_count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography variant="h6" gutterBottom>
                                Rates
                            </Typography>
                            <TableContainer component={Paper} style={{ flex: 1, overflow: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                                <Table size="small" aria-label="rates table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Rate ID</TableCell>
                                            <TableCell>Active</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Markup</TableCell>
                                            <TableCell>Number of People</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rates.map(rate => (
                                            <TableRow key={rate.rate_id}>
                                                <TableCell>{rate.rate_id}</TableCell>
                                                <TableCell>{rate.rate_active ? 'Yes' : 'No'}</TableCell>
                                                <TableCell>{rate.rate_name}</TableCell>
                                                <TableCell>{rate.rate_markup}</TableCell>
                                                <TableCell>{rate.rate_people}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default RentalsUnitedCMManager;

