import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Box, Grid, Typography, Card, CardContent, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function MonitoringDashboard() {
    const [statusData, setStatusData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedTest, setSelectedTest] = useState(null);

    useEffect(() => {
        // Fetch data from the API
        Axios.get('/LeviathanMonitoring/StatusAPI')
            .then(response => {
                setStatusData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    const handleOpenDialog = (test) => {
        setSelectedTest(test);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedTest(null);
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (!statusData) {
        return <Typography variant="h6" color="error">No data available.</Typography>;
    }

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                Monitoring Dashboard
            </Typography>
            <Typography variant="h6" gutterBottom>
                Last Runtime: {statusData.last_runtime}
            </Typography>
            <Grid container spacing={3}>
                {statusData.last_run.map((module, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    {module.module}
                                </Typography>
                                {module.tests.map((test, idx) => (
                                    <Box
                                        key={idx}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        mb={1}
                                        p={1}
                                    >
                                        <Typography variant="body1">{test.name}</Typography>
                                        <Box display="flex" alignItems="center">
                                            {test.status == "PASSED" ? (
                                                <CheckCircleIcon color="success" />
                                            ) : (
                                                <CancelIcon color="error" />
                                            )}
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleOpenDialog(test)}
                                                style={{ marginLeft: 8 }}
                                            >
                                                Details
                                            </Button>
                                        </Box>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Dialog for Test Details */}
            {selectedTest && (
                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle>Test Details: {selectedTest.name}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">Start Time: {new Date(selectedTest.start_time).toLocaleString()}</Typography>
                        <Typography variant="body1">End Time: {new Date(selectedTest.end_time).toLocaleString()}</Typography>
                        <Typography variant="body1" color={selectedTest.status == 'PASSED' ? 'success.main' : 'error.main'}>
                            Result: {selectedTest.result ? 'Pass' : 'Fail'}
                        </Typography>
                        <Typography>{selectedTest.output}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">Close</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
}

