import { Box, Typography, IconButton, List, ListItem, Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from "@mui/icons-material/Settings";

export default function TitleBox({ title, refreshTime, error, onRefresh, onSettings }) {
    return (
        <Box
            width="100%"
            height={50}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="lightgray"
            position="relative"
            boxShadow="0 2px 4px rgba(0,0,0,0.1)"
        >
            <Typography variant="h6">{title}</Typography>
            <Box display="flex" alignItems="center">
                <Typography variant="body2">
                    {`Last Refresh: ${refreshTime}`}
                </Typography>
                {error && <WarningIcon color="error" sx={{ ml: 1 }} />}
            </Box>
            <IconButton
                size="small"
                onClick={() => onRefresh()}
                sx={{ position: "absolute", right: 8, top: 8 }}
            >
                <RefreshIcon />
            </IconButton>
            {onSettings ??
                <IconButton
                    size="small"
                    onClick={() => onSettings()}
                    disabled={true}
                    sx={{ position: "absolute", right: 32, top: 8 }}
                >
                    <SettingsIcon />
                </IconButton>}
        </Box >
    );
}
