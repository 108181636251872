import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, GlobalStyles, Alert, LinearProgress, Fade, Button } from '@mui/material';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { setupAxios } from '../../common/setupaxios';

const ReservationList = ({ title, reservations, checkin }) => {
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = (e) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.target;
    setIsScrollable(scrollWidth > clientWidth && scrollLeft < scrollWidth - clientWidth);
  };

  return (
    <Box sx={{ position: 'relative', overflowX: 'auto', borderRadius: 2 }}>
      <Fade in={isScrollable}>
        <Box sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          width: '16px',
          background: 'linear-gradient(to left, rgba(0, 0, 0, 0.6), transparent)'
        }}> More Data </Box>
      </Fade>

      <TableContainer
        component={Paper}
        sx={{ backgroundColor: '#1E1E1E', borderRadius: 2 }}
        onScroll={handleScroll}
      >
        <Table size="medium" sx={{ minWidth: 650 }} aria-label={`${title} table`}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white', backgroundColor: '#333' }}>{checkin ? `Check-ins (${reservations.length})` : `Check-outs (${reservations.length})`}</TableCell>
              <TableCell sx={{ color: 'white', backgroundColor: '#333' }}>Interval Ref</TableCell>
              <TableCell sx={{ color: 'white', backgroundColor: '#333' }}>Property name</TableCell>
              <TableCell sx={{ color: 'white', backgroundColor: '#333' }}>Property Status</TableCell>
              {checkin && <>
                <TableCell sx={{ color: 'white', backgroundColor: '#333' }}>Cott</TableCell>
                <TableCell sx={{ color: 'white', backgroundColor: '#333' }}>High Chair</TableCell>
                <TableCell sx={{ color: 'white', backgroundColor: '#333' }}>Extra bed </TableCell>
              </>}
            </TableRow>
          </TableHead>
          <TableBody>
            {reservations.map((reservation) => (
              <TableRow key={reservation.stayid} sx={{ backgroundColor: '#2E2E2E' }}>
                <TableCell className='text-3xl' sx={{ color: 'white', backgroundColor: 'gray' }}>
                  {checkin ? <FlightLandIcon sx={{ color: 'white' }} /> : <FlightTakeoffIcon sx={{ color: 'red' }} />}
                </TableCell>
                <TableCell className='text-3xl' sx={{ color: 'white', backgroundColor: 'gray' }}>{reservation.internal_ref}</TableCell>
                <TableCell className='text-3xl' sx={{ color: 'white', backgroundColor: 'gray' }}>{reservation.o_name}</TableCell>
                <TableCell className='text-3xl' sx={{ color: 'white', backgroundColor: 'gray' }}>{checkin ? reservation.prop_date_from_status : reservation.prop_date_to_status}</TableCell>
                {checkin && <>
                  <TableCell className='text-3xl' sx={{
                    color: 'white',
                    backgroundColor: reservation.require_cottage && reservation.require_cottage > 0 ? 'red' : 'gray'
                  }}>
                    {reservation.require_cottage}</TableCell>
                  <TableCell className='text-3xl' sx={{
                    color: 'white',
                    backgroundColor: reservation.require_highchair && reservation.require_highchair > 0 ? 'red' : 'gray'
                  }}>
                    {reservation.require_highchair}</TableCell>
                  <TableCell className='text-3xl' sx={{
                    color: 'white',
                    backgroundColor: reservation.require_extrabed && reservation.require_extrabed > 0 ? 'red' : 'gray'
                  }}>
                    {reservation.require_extrabed}</TableCell>
                </>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default function Checkinout({ showCheckins = true, showCheckouts = true }) {
  const [checkins, setCheckins] = useState([]);
  const [checkouts, setCheckouts] = useState([]);
  const [dayOffset, setDayOffset] = useState(0); // Days from today
  const [currentDate, setCurrentDate] = useState(new Date());
  const [countdown, setCountdown] = useState(20);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const getCurrentDay = (offset) => {
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + offset);
    return targetDate;
  };

  const fetchReservations = async (selectedDate) => {
    try {
      setError(null); // Reset error state before fetching
      const formattedDate = selectedDate.toISOString().split('T')[0];
      axios.defaults.headers.common['LeviathanAuth'] = '4dc2f252f13d2bf77715ee7fec4317e6';

      if (showCheckins) {
        const checkinResponse = await axios.get(`/Office/reservations/stat?type=1&date_from=${formattedDate}&prop_stat=1&allow_hec=1`);
        setCheckins(checkinResponse.data.reservations);
      }

      if (showCheckouts) {
        const checkoutResponse = await axios.get(`/Office/reservations/stat?type=2&date_from=${formattedDate}&prop_stat=1&allow_hec=1`);
        setCheckouts(checkoutResponse.data.reservations);
      }
    } catch (error) {
      setError('Failed to load reservations. Please try again.');
      console.error('Error fetching reservations:', error);
    }
  };


  useEffect(() => {
    setupAxios();
    const currentDay = getCurrentDay(dayOffset);
    setCurrentDate(currentDay);
    fetchReservations(currentDay);

    const refreshInterval = setInterval(() => {
      const updatedCurrentDay = getCurrentDay(dayOffset); // Recalculate the day before every refresh
      setCurrentDate(updatedCurrentDay);
      fetchReservations(updatedCurrentDay); // Refresh data for the current day
    }, 20000);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 20));
    }, 1000);

    return () => {
      clearInterval(refreshInterval);
      clearInterval(countdownInterval);
    };
  }, [dayOffset, showCheckins, showCheckouts]);

  // Handle day change manually
  const handlePrevDay = () => {
    if (checkallowBackInTime()) { // Prevent going below today
      setDayOffset((prevOffset) => prevOffset - 1);
    }
  };

  const checkallowBackInTime = () => {
    if ((searchParams.get('allowbackintime')) && (searchParams.get('allowbackintime') === '1')) {
      return true;
    }
    if (dayOffset > 0) { // Prevent going below today
      return true;
    };
    return false;
  };

  const handleNextDay = () => {
    setDayOffset((prevOffset) => prevOffset + 1);
  };

  // Calculate progress percentage
  const progressValue = (countdown / 20) * 100;

  return (
    <>
      <GlobalStyles
        styles={{
          body: { backgroundColor: '#121212', color: 'white', margin: 0, padding: 0, minHeight: '100vh' },
        }}
      />
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
          <Typography variant="h5" component="div">
            Current Date: {currentDate.toDateString()}
          </Typography>

          {/* Date Change Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
            <Button variant="contained" color="primary" onClick={handlePrevDay} sx={{ marginRight: 1 }} disabled={!checkallowBackInTime()}>
              Previous Day
            </Button>
            <Button variant="contained" color="primary" onClick={handleNextDay}>
              Next Day
            </Button>
          </Box>

          {/* Progress bar */}
          <Box sx={{ width: '100%', marginY: 2 }}>
            <LinearProgress variant="determinate" value={progressValue} sx={{ height: 8, borderRadius: 2 }} />
          </Box>
        </Box>

        {/* Error Handling */}
        {error && (
          <Box sx={{ marginBottom: 2 }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        <Grid container spacing={2}>
          {showCheckins && (
            <Grid item xs={12} md={showCheckouts ? 6 : 12}>
              <ReservationList key={`checkins`} title="Check-ins" reservations={checkins} checkin={true} />
            </Grid>
          )}
          {showCheckouts && (
            <Grid item xs={12} md={showCheckins ? 6 : 12}>
              <ReservationList key={`checkouts`} title="Check-outs" reservations={checkouts} checkin={false} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

