import {
    Box,
    TextField,
    Button,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,
} from '@mui/material';

export default function ItemsTable(
    {
        items, onIncrement, onDecrement,
        onFieldChange, onRemove,
        requireAmount, allowDecrement = true,
        showWarehouseName = false,
        allowRemove = true, allowAmountChange = true
    }) {
    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Item Name</TableCell>
                        {showWarehouseName && <TableCell>Warehouse</TableCell>}
                        <TableCell align="center">Quantity</TableCell>
                        {requireAmount && (
                            <>
                                <TableCell align="center">Net / q</TableCell>
                                <TableCell align="center">VAT / q</TableCell>
                                <TableCell align="center">Gross / q</TableCell>
                                <TableCell align="center">Total</TableCell>
                            </>
                        )}
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.item_name}</TableCell>
                            {showWarehouseName && <TableCell>{item.warehouse_name}</TableCell>}
                            <TableCell align="center">
                                {allowDecrement ? (
                                    <TextField
                                        size='small'
                                        type="number"
                                        value={item.quantity}
                                        disabled={!allowDecrement}
                                        onChange={(e) => onFieldChange(index, 'quantity', parseInt((e.target.value, '')) < 1 ? 1 : parseInt(e.target.value))}
                                        fullWidth
                                        sx={{ width: '80px' }} // Adjust width as needed
                                    />) : (
                                    <Typography variant='body1' color={item.quantity > 0 ? 'green' : 'red'} >{item.quantity}</Typography>
                                )}
                            </TableCell>
                            {requireAmount && (
                                <>
                                    <TableCell align="center">
                                        <TextField
                                            size='small'
                                            type="number"
                                            value={item.net}
                                            disabled={!allowAmountChange}
                                            onChange={(e) => onFieldChange(index, 'net', e.target.value)}
                                            fullWidth
                                            sx={{ width: '80px' }} // Adjust width as needed
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            type="number"
                                            size='small'
                                            value={item.vat}
                                            disabled={!allowAmountChange}
                                            onChange={(e) => onFieldChange(index, 'vat', e.target.value)}
                                            fullWidth
                                            sx={{ width: '80px' }} // Adjust width as needed
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            size='small'
                                            type="number"
                                            value={item.gross}
                                            disabled={!allowAmountChange}
                                            onChange={(e) => onFieldChange(index, 'gross', e.target.value)}
                                            fullWidth
                                            sx={{ width: '80px' }} // Adjust width as needed
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            size='small'
                                            type="number"
                                            value={item.total}
                                            disabled={true}
                                            fullWidth
                                            sx={{ width: '80px' }} // Adjust width as needed
                                        />
                                    </TableCell>

                                </>
                            )}
                            <TableCell align="center">
                                <Button size='small' disabled={!allowRemove} color="secondary" onClick={() => onRemove(index)}>
                                    Remove
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

