import React, { useEffect, useState } from 'react';
import axios from 'axios';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const PropertyListItem = ({ property }) => (
    <ListItem key={property.property_id} sx={{ pl: 4 }} button>
        <ListItemAvatar>
            {property.listing_img ? (
                <Avatar src={property.listing_img} />
            ) : (
                <Avatar>
                    <img src="/path/to/placeholder/image.jpg" alt={property.name} />
                </Avatar>
            )}
        </ListItemAvatar>
        <ListItemText
            primary={property.name}
            secondary={
                <>
                    <Typography component="span" variant="body2" color="textPrimary">
                        {property.prop_status}
                    </Typography>
                    {property.otas.length > 0 && (
                        <Typography component="span" variant="body2" color="textSecondary">
                            OTAs: {property.otas.join(', ')}
                        </Typography>
                    )}
                </>
            }
        />
        {property.PropertyList && property.PropertyList.length > 0 && (
            <PropertyList properties={property.PropertyList} />
        )}
    </ListItem>
);

const PropertyList = ({ properties }) => (
    <List>
        {properties.map(property => (
            <React.Fragment key={property.property_id}>
                <ListItem>
                    <ListItemText
                        primary={property.name}
                        secondary={`ID: ${property.property_id} - Status: ${property.prop_status}`}
                    />
                </ListItem>
                <List component="div" disablePadding>
                    {property.PropertyList && property.PropertyList.length > 0 && (
                        property.PropertyList.map(subProperty => (
                            <PropertyListItem key={subProperty.property_id} property={subProperty} />
                        ))
                    )}
                </List>
                <Divider />
            </React.Fragment>
        ))}
    </List>
);

const PropertyViewer = () => {
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        axios.get('/Office/Property/List?hier_id=3')
            .then(response => {
                setProperties(response.data.MainPropertyList);
            })
            .catch(error => {
                console.error('Error fetching the properties', error);
            });
    }, []);

    return (
        <PropertyList properties={properties} />
    );
};

export default PropertyViewer;

