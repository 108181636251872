import React, { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Button,
    Snackbar,
    Alert,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    Typography,
    TablePagination,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import axios from 'axios';
import mqtt from 'mqtt';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import ItemsDialog from './pick_item';
import { orangeColor } from '../../css/common_sx';

export default function CRMPhysicalItems() {
    const [client, setClient] = useState(null);
    const [mqttConnectionStatus, setMqttConnectionStatus] = useState('Disconnected');
    const [globalFilter, setGlobalFilter] = useState('');
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const mqttChannel = 'device/1678671';
    const [showNewItemDialog, setShowNewItemDialog] = useState(false);
    const [showItemSelection, setShowItemSelection] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [sunNumber, setSunNumber] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const initMqttConnection = () => {
            const mqttClient = mqtt.connect('ws://localhost:8083/mqtt');

            mqttClient.on('connect', () => {
                setMqttConnectionStatus('Connected');
                mqttClient.subscribe(mqttChannel);
            });

            mqttClient.on('error', (err) => {
                setMqttConnectionStatus('Connection Error');
                mqttClient.end();
            });

            mqttClient.on('reconnect', () => {
                setMqttConnectionStatus('Reconnecting...');
            });

            mqttClient.on('offline', () => {
                setMqttConnectionStatus('Disconnected');
            });

            mqttClient.on('message', (topic, message) => {
                if (topic === mqttChannel) {
                    const data = JSON.parse(message.toString());
                    if (data.sun_number) {
                        setSunNumber(data.sun_number);
                    }
                }
            });

            setClient(mqttClient);

            return mqttClient;
        };

        const mqttClient = initMqttConnection();

        return () => {
            mqttClient.end();
        };
    }, []);
    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get('/Office/CRM/Items/physical_items');
            setItems(response.data);
        } catch (err) {
            setError('Failed to fetch items');
            setSnackbarMessage('Failed to fetch items');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {


        fetchData();
    }, []);

    const handleOpenNewItemDialog = () => {
        setShowNewItemDialog(true);
    };

    const handleCloseNewItemDialog = () => {
        setShowNewItemDialog(false);
        setSelectedItem(null);
        setSunNumber('');
    };

    const handleItemSelect = (item) => {
        setSelectedItem(item);
        setShowItemSelection(false);
    };

    const handleSaveNewItem = async () => {
        if (selectedItem && sunNumber) {
            const newItem = {
                physical_item_id: items.length + 1, // Replace with actual ID if necessary
                item_id: selectedItem.id, // Use selected item's ID
                sun_number: sunNumber,
            };

            try {
                const response = await axios.post('/Office/CRM/Items/physical_items', newItem);
                if (response.status === 200) {
                    fetchData();
                    setSnackbarMessage('Item saved successfully!');
                    setSnackbarSeverity('success');
                } else if (response.status === 400) {
                    setSnackbarMessage(response.data.message || 'Failed to save item');
                    setSnackbarSeverity('error');
                }
            } catch (err) {
                setSnackbarMessage(err.response?.data?.message || 'Failed to save item');
                setSnackbarSeverity('error');
            } finally {
                setSnackbarOpen(true);
                handleCloseNewItemDialog();
            }
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const groupByItemName = (items) => {
        return items.reduce((groups, item) => {
            const group = groups[item.item_name] || [];
            group.push(item);
            groups[item.item_name] = group;
            return groups;
        }, {});
    };

    const groupedItems = groupByItemName(items);
    const filteredItems = Object.entries(groupedItems)
        .filter(([itemName]) => itemName.toLowerCase().includes(globalFilter.toLowerCase()));

    const pagedItems = filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const renderNewItemDialog = () => (
        <Dialog open={showNewItemDialog} onClose={handleCloseNewItemDialog} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h6">Add New Physical Item</Typography>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={3} mt={2}>
                    <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setShowItemSelection(true)}
                        >
                            Select Item
                        </Button>
                        <Typography variant="body1"><strong>Selected Item:</strong> {selectedItem?.name || 'None'}</Typography>
                        <Typography variant="body2" color="textSecondary"><strong>SKU:</strong> {selectedItem?.sku || 'N/A'}</Typography>
                    </Box>
                    <TextField
                        margin="dense"
                        label="SUN Number"
                        type="text"
                        fullWidth
                        value={sunNumber}
                        onChange={(e) => setSunNumber(e.target.value)}
                        variant="outlined"
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '16px' }}>
                <Button onClick={handleCloseNewItemDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSaveNewItem} color="primary" variant="contained" disabled={!selectedItem || !sunNumber}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
                marginBottom={2}
                borderRadius={2}
                boxShadow={3}
                bgcolor={orangeColor} // Adjust this or use `theme.palette.background.paper` for a more neutral tone
            >
                <TextField
                    label={`Search (MQTT: ${mqttConnectionStatus} on ${mqttChannel})`}
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    fullWidth
                    sx={{
                        bgcolor: 'white', // White background to highlight the search bar
                        borderRadius: 1, // Smooth edges for the search bar
                        marginRight: 2, // Add space between the search bar and button
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpenNewItemDialog}
                    sx={{
                        paddingLeft: 2,
                        paddingRight: 2,
                        textTransform: 'none', // No uppercase text for better readability
                    }}
                >
                    Add Item
                </Button>
            </Box>
            <Box position="relative" width="100%" height="100%">
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Item Name & SKU</TableCell>
                                <TableCell>Sun Number</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Created By</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <>
                                    {[...Array(5)].map((_, index) => (
                                        <TableRow key={index}>
                                            <TableCell colSpan={5}>
                                                <Skeleton variant="rectangular" height={30} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            ) : (
                                pagedItems.map(([itemName, itemGroup]) => (
                                    <React.Fragment key={itemName}>
                                        {/* Item Name Header Row */}
                                        <TableRow>
                                            <TableCell colSpan={5} style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                                                <Box display="flex" alignItems="center">
                                                    <ImageIcon style={{ marginRight: 8 }} />
                                                    <Typography variant="subtitle1" fontWeight="bold">
                                                        {itemName} (SKU: {itemGroup[0].sku})
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        {/* Details for each item */}
                                        {itemGroup.map((item) => (
                                            <TableRow key={item.physical_item_id}>
                                                <TableCell>{item.physical_item_id}</TableCell>
                                                <TableCell>{item.item_name}</TableCell>
                                                <TableCell>{item.sun_number}</TableCell>
                                                <TableCell>{new Date(item.created_at).toLocaleString()}</TableCell>
                                                <TableCell>{item.created_by}</TableCell>
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{
                        '.MuiTablePagination-root': {
                            backgroundColor: orangeColor, // Background color of the pagination container
                        },
                        '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                            color: 'white', // Text color for the label and displayed rows
                        },
                        '.MuiTablePagination-selectIcon': {
                            color: 'white', // Color for the dropdown icon
                        },
                        '.MuiButtonBase-root': {
                            color: 'white', // Color for the pagination buttons (next/previous)
                        },
                    }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>

            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            )}

            {/* Render New Item Dialog */}
            {renderNewItemDialog()}

            {/* Items Dialog for selecting an item */}
            <ItemsDialog open={showItemSelection} onClose={handleItemSelect} />

            {/* Snackbar for showing success or error messages */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

