import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Chart from 'react-apexcharts'
import axios from 'axios';
import { ListBox } from 'primereact/listbox';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import TreeView, { flattenTree } from "react-accessible-treeview";
import { maxHeight } from '@mui/system';
import PropertiInfo from './propertyinfo';
const PropertiesList = ({ property_id }) => {
    const [properties, setProperties] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState(null);
    const fetchProperties = async () => {
        let response = await axios.get('https://uploads.shortletsmalta.com/StaffGetProperties?hier_id=12&type_id=5')
        console.debug('Properties', response.data);
        setProperties(response.data.MainPropertyList);
    }
    useEffect(() => {
        fetchProperties();
    }, [])

    const countryTemplate = (option) => {
        //console.debug('OTA: ', option.otas)
        return (
            <Box display='flex' flexDirection='column'>
                <Box flex='1'>
                    {option.name}
                </Box>
                <Box flex='1' flexDirection='row' display='flex'>
                    {option.otas === undefined ? '' : option.otas.map((item, index) => (
                        <Box flex='1' key={index} style={{ marginRight: '5px', maxHeight: '15px', maxWidth: '16px' }}>
                            <img
                                src={`https://pcrms.leviathan.systems${item}`}
                                alt={`OTA ${index}`}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box >
        )
    };
    return (
        <Box display="flex" flexDirection="row" flex="1" width="100%" height="100%" margin="5px">
            <Box height="100%" width="100%" flex="1" flexDirection="column" display="flex">
                <Box>
                    <Typography variant="h6">Pace report</Typography>
                </Box>
                <Box flex="1" width="100%" position="relative">
                    <ListBox itemTemplate={countryTemplate} filter
                        style={{ position: 'absolute', overflow: 'auto', top: '0', bottom: '0', left: '0', right: '0' }}
                        value={selectedProperties}
                        onChange={(e) => setSelectedProperties(e.value)}
                        options={properties}
                        optionLabel="name"
                        optionValue="property_id" />
                </Box>
            </Box>
            <Box flex='4'>
                <PropertiInfo property_id={1} />
            </Box>
        </Box>
    );
};

export default PropertiesList;
