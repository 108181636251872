import Chart from 'react-apexcharts';
import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, List, ListItem, Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import axios from 'axios';
import TitleBox from './Title';
export default function Bookings({ title, date, bgcolor, showTimeOnly, enableAutoRefresh }) {
    const [bookings, setBookings] = useState([]);
    const [refreshTime, setRefreshTime] = useState(new Date().toLocaleTimeString());
    const [error, setError] = useState(false);

    const fetchData = async () => {
        try {
            const res = await axios.get(`/Office/reservations?type=5&date_from=${date}`);
            if (res.data && res.data.reservations) {
                const sortedReservations = res.data.reservations.sort((a, b) => new Date(a.channel_create_date) - new Date(b.channel_create_date));
                setBookings(sortedReservations);
                setError(false);
            } else {
                setError(true);
            }
            setRefreshTime(new Date().toLocaleTimeString());
        } catch (err) {
            setError(true);
        }
    };

    useEffect(() => {
        fetchData();
        if (enableAutoRefresh) {
            const interval = setInterval(fetchData, 60000); // Refresh every 60 seconds
            return () => clearInterval(interval); // Cleanup on unmount
        }
    }, []);

    const chartOptions = {
        chart: {
            type: 'line',

        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false
            }
        },
        yaxis: {
            title: {
                text: 'Guest Price',
            },
        },
        stroke: {
            curve: 'smooth',
        },
        markers: {
            size: 1,
        }
    };

    const chartSeries = [
        {
            name: 'Guest Paid Price',
            data: bookings.map(booking => ({
                x: new Date(booking.channel_create_date).toLocaleString(),
                y: booking.guest_price
            })),
        },
    ];

    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center" mb={2}>
            <TitleBox title={title} refreshTime={refreshTime} error={error} onRefresh={fetchData} />
            <Box
                width="100%"
                height={300}
                bgcolor={bgcolor}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box position="relative" display="flex" flex="1" width="100%" height="100%">
                    <Box position="absolute" flex={1} top={0} bottom={0} right={0} left={0}>
                        <Chart options={chartOptions} series={chartSeries} width="100%" height="100%" />
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}
