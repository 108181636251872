const getDateString = (indate, offsetDays = 0) => {
    const currentDate = new Date(indate);
    currentDate.setDate(currentDate.getDate() + offsetDays);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export default getDateString;

