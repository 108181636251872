import React, { useState, useEffect } from "react";
import {
    Box, Button, Typography,
    TableContainer, Paper,
    TextField, Table, TableBody, TableCell, TableHead, TableRow, Stepper, Step, StepLabel, MenuItem, Select, InputLabel, FormControl, Breadcrumbs, Link
} from "@mui/material";
import axios from "axios";
import { orangeColor } from "../css/common_sx";
const LeviathanLevels = [
    { level: 1, color: "#FF0000", name: "Emergency", icon: "❗" },
    { level: 2, color: "#FF4500", name: "Alert", icon: "🚨" },
    { level: 3, color: "#FF8C00", name: "Critical", icon: "⚡" },
    { level: 4, color: "#FFD700", name: "Error", icon: "⚠️" },
    { level: 5, color: "#FFFF00", name: "Warning", icon: "⚠️" },
    { level: 6, color: "#00FFFF", name: "Notice", icon: "ℹ️" },
    { level: 7, color: "#87CEEB", name: "Info", icon: "ℹ️" },
    { level: 8, color: "#00FF00", name: "Debug", icon: "✅" }
];

const SystemTasks = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const [levelFilter, setLevelFilter] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
    const [messageFilter, setMessageFilter] = useState("");
    const [data, setData] = useState([]);

    const steps = ['Select App', 'Select Levels', 'View Logs'];

    // Fetch available apps for the first step
    useEffect(() => {
        const fetchApps = async () => {
            try {
                const response = await axios.get("/LeviathanMonitoring/log/getApps");
                setApps(response.data);
            } catch (error) {
                console.error("Error fetching apps:", error);
            }
        };
        fetchApps();
    }, []);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAppSelect = (event) => {
        setSelectedApp(event.target.value);
    };

    const handleLevelChange = (event) => {
        setLevelFilter(event.target.value);
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`/LeviathanMonitoring/log?date=${selectedDate}&app_id=${selectedApp}&levels=${levelFilter.join(",")}`);
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (activeStep === 2) {
            fetchData();
        }
    }, [activeStep]);

    return (
        <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%" margin="5px">
            <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={2}
                borderRadius={2} boxShadow={3} bgcolor={orangeColor} >
                <Box display='flex' sx={{ flexDirection: 'row' }}>
                    <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        label="Select Date"
                        variant="outlined"
                    />
                    <TextField
                        label="Search Message"
                        value={messageFilter}
                        onChange={(e) => setMessageFilter(e.target.value)}
                        variant="outlined"
                    />
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit">
                        {selectedApp ? apps.find(app => app.id === selectedApp)?.application_name : "Select App"}
                    </Link>
                    <Link underline="hover" color="inherit">
                        {levelFilter.length > 0 ? levelFilter.map(level => LeviathanLevels.find(l => l.level === level)?.name).join(", ") : "Select Levels"}
                    </Link>
                </Breadcrumbs>
            </Box>

            {/* Stepper */}
            {/*<Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper> */}

            {/* Step Content */}
            {
                activeStep === 0 && (
                    <Paper>
                        <FormControl fullWidth>
                            <InputLabel>Select Application</InputLabel>
                            <Select
                                value={selectedApp}
                                onChange={handleAppSelect}
                            >
                                {apps.map((app) => (
                                    <MenuItem key={app.id} value={app.id}>
                                        {app.application_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Paper>
                )
            }

            {
                activeStep === 1 && (
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel>Select Levels</InputLabel>
                            <Select
                                multiple
                                value={levelFilter}
                                onChange={handleLevelChange}
                                renderValue={(selected) => selected.map(level => LeviathanLevels.find(l => l.level === level)?.name).join(", ")}
                            >
                                {LeviathanLevels.map((level) => (
                                    <MenuItem key={level.level} value={level.level}>
                                        {level.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )
            }

            {
                activeStep === 2 && (
                    <TableContainer component={Paper} sx={{ marginBottom: '10px' }}>

                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Icon</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>App Name</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>Alias</TableCell>
                                    <TableCell>Occurrence Count</TableCell>
                                    <TableCell>Last Occurrence</TableCell>
                                    <TableCell>First Occurrence</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{LeviathanLevels.find(l => l.level === row.level)?.icon}</TableCell>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.app_name}</TableCell>
                                        <TableCell>{row.message}</TableCell>
                                        <TableCell>{row.alias}</TableCell>
                                        <TableCell>{row.occurrence_count}</TableCell>
                                        <TableCell>{new Date(row.last_occurrence_time).toLocaleString()}</TableCell>
                                        <TableCell>{new Date(row.first_occurrence_time).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }

            {/* Navigation Buttons */}
            <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="contained"
                >
                    Back
                </Button>
                <Button
                    disabled={activeStep === steps.length - 1 || (activeStep === 0 && !selectedApp) || (activeStep === 1 && levelFilter.length === 0)}
                    onClick={handleNext}
                    variant="contained"
                >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </Box>
        </Box >
    );
};

export default SystemTasks;

