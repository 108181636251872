import React, { useEffect, useState, useCallback } from 'react';
import {
    Box,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    TablePagination,
    Typography,
    Snackbar,
    Alert,
    LinearProgress,
    Skeleton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import axios from 'axios';
import { searchFunction } from '../../common/search_function';
export default function CRMWarehousesDialog({ open, onClose }) {
    const [globalFilter, setGlobalFilter] = useState('');
    const [warehouses, setWarehouses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    // Fetch warehouse data when the dialog opens
    useEffect(() => {
        if (open) {
            setLoading(true);
            axios.get('/Office/CRM/Warehouses')
                .then((response) => {
                    setWarehouses(response.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setError('Failed to fetch data');
                    setLoading(false);
                });
        }
    }, [open]);

    // Handle pagination changes
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Close dialog and pass selected item
    const handleClose = useCallback((item) => {
        onClose(item);
    }, [onClose]);

    const filteredWarehouses = searchFunction(globalFilter, ['name', 'full_address'], warehouses);

    // Group warehouses by owner
    const groupedWarehouses = filteredWarehouses.reduce((acc, warehouse) => {
        if (!acc[warehouse.owner_name]) {
            acc[warehouse.owner_name] = [];
        }
        acc[warehouse.owner_name].push(warehouse);
        return acc;
    }, {});

    // Handle displayed rows
    const displayedWarehouses = Object.keys(groupedWarehouses)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(owner_name => (
            <React.Fragment key={owner_name}>
                <TableRow>
                    <TableCell colSpan={6} style={{ backgroundColor: '#f0f0f0' }}>
                        <Typography variant="subtitle1">{owner_name}</Typography>
                    </TableCell>
                </TableRow>
                {groupedWarehouses[owner_name].map((warehouse) => (
                    <TableRow
                        key={warehouse.id}
                        onClick={() => handleClose(warehouse)}
                        hover
                        style={{ cursor: 'pointer' }}
                    >
                        <TableCell>{warehouse.id}</TableCell>
                        <TableCell>{warehouse.name}</TableCell>
                        <TableCell>{warehouse.full_address || 'N/A'}</TableCell>
                        <TableCell>{warehouse.capacity || 'N/A'}</TableCell>
                        <TableCell>
                            {warehouse.current_storage ? (
                                <Box display="flex" alignItems="center">
                                    <Box width="100%" mr={1}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={(warehouse.current_storage / warehouse.capacity) * 100}
                                        />
                                    </Box>
                                    <Typography variant="body2" color="textSecondary">{`${Math.round(
                                        (warehouse.current_storage / warehouse.capacity) * 100
                                    )}%`}</Typography>
                                </Box>
                            ) : (
                                'N/A'
                            )}
                        </TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                ))}
            </React.Fragment>
        ));

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(null)}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>Warehouse Information</DialogTitle>
            <DialogContent dividers>
                <Box display="flex" flexDirection="column" width="100%" height="100%">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
                        <TextField
                            label="Search"
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Box position="relative" width="100%" height="100%">
                        {loading ? (
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><Skeleton variant="text" /></TableCell>
                                            <TableCell><Skeleton variant="text" /></TableCell>
                                            <TableCell><Skeleton variant="text" /></TableCell>
                                            <TableCell><Skeleton variant="text" /></TableCell>
                                            <TableCell><Skeleton variant="text" /></TableCell>
                                            <TableCell><Skeleton variant="text" /></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.from(new Array(rowsPerPage)).map((_, index) => (
                                            <TableRow key={index}>
                                                <TableCell><Skeleton variant="text" /></TableCell>
                                                <TableCell><Skeleton variant="text" /></TableCell>
                                                <TableCell><Skeleton variant="text" /></TableCell>
                                                <TableCell><Skeleton variant="text" /></TableCell>
                                                <TableCell><Skeleton variant="rectangular" width="100%" height={10} /></TableCell>
                                                <TableCell><Skeleton variant="text" /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : error ? (
                            <Snackbar open autoHideDuration={6000}>
                                <Alert severity="error">{error}</Alert>
                            </Snackbar>
                        ) : (
                            <>
                                <TableContainer component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Warehouse</TableCell>
                                                <TableCell>Location</TableCell>
                                                <TableCell>Capacity</TableCell>
                                                <TableCell>Used Capacity</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {displayedWarehouses}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[20, 30, 50]}
                                    component="div"
                                    count={Object.keys(groupedWarehouses).length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(null)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

