import React from 'react';
import { Box, Typography, Button } from '@mui/material';

import '../css/LoadingAnimation.css';

const LoadingAnimation = ({ loading, loadingText }) => {
    return (
        <Box
            className={`loading-container ${loading ? 'loading' : 'loaded'}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgcolor="rgba(255, 255, 255, 0.8)"
        >
            <Box className="loading-logo" component="img" src="https://pcrms.leviathan.systems/files/images/imprint.png" alt="Loading" />
            <Typography variant="h6" mt={2}>{loadingText}</Typography>
        </Box>
    );
};

export default LoadingAnimation;
