import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Chart from 'react-apexcharts'
import axios from 'axios';
import { ListBox } from 'primereact/listbox';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import TreeView, { flattenTree } from "react-accessible-treeview";

const PropertiInfo = ({ property_id }) => {
    return (
        <Box display="flex" flexDirection="row" flex="1" width="100%" height="100%" margin="5px">
            {property_id}
        </Box>
    );
};

export default PropertiInfo
