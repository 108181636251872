import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Chart from 'react-apexcharts'
import axios from 'axios';
import { ListBox } from 'primereact/listbox';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import TreeView, { flattenTree } from "react-accessible-treeview";

const UserAccessControllManagement = () => {
    const [users, setUsers] = useState([]);
    const [dacgroups, setDACGroups] = useState([]);
    const [dacs, setDACs] = useState([]);
    const [selectedDAC, setselectedDAC] = useState([]);
    const [selecteduser, setSelectedUser] = useState();

    const fetchdata = async () => {
        try {
            let response = await axios.get(`https://uploads.shortletsmalta.com/Office/HR/Users?company_id=1`);
            console.debug('Users', response.data);
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchDACGroups = async () => {
        try {
            let response = await axios.get(`https://uploads.shortletsmalta.com/Office/Security/DACGroups`);
            console.debug('DAC Groups:', response.data);
            setDACGroups(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fetchDACS = async () => {
        try {
            let response = await axios.get(`https://uploads.shortletsmalta.com/Office/Security/DACSByCompany`);
            console.debug('DACS ', response.data);
            setDACs(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        fetchdata();
        fetchDACGroups();
        fetchDACS();
    }, []);

    useEffect(() => { }, [selecteduser]);

    const countryTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div style={{ color: option.enabled ? 'inherit' : 'red' }}>
                    {option.ro_comment}
                </div>
            </div >
        );
    };

    return (
        <Box display="flex" flexDirection="row" flex="1" width="100%" height="100%" margin="5px">
            <Box height="100%" width="100%" flex="1" flexDirection="column" display="flex">
                <Box>
                    <Typography variant="h6">DAC's Staff Users</Typography>
                </Box>
                <Box flex="1" width="100%" position="relative">
                    <ListBox filter itemTemplate={countryTemplate} style={{ position: 'absolute', overflow: 'auto', top: '0', bottom: '0', left: '0', right: '0' }} value={selecteduser} onChange={(e) => setSelectedUser(e.value)} options={users} optionLabel="ro_comment" optionValue="id" />
                </Box>
            </Box>
            <Box flex="2">
                <Box position="relative" width="100%" height="100%">
                    <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0" >
                        <Box overflow="hidden">
                        </Box>
                        <table style={{ width: '100%' }}>
                            <thead style={{
                                position: "sticky",
                                top: 0,
                                zIndex: 10,
                                backgroundColor: "#f5f5f5",
                            }}>
                                <tr>
                                    <td>CNT</td><td>Names</td>
                                </tr>
                            </thead>
                            <tbody>
                                {dacgroups.map((item, index) => (
                                    <tr onClick={() => {
                                        setselectedDAC(item.actions)
                                    }}>
                                        <td>{item.actions.length}</td>
                                        <td>{item.ro_comment}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Box>
                </Box>
            </Box>
            <Box flex="2" >
                <Box position="relative" width="100%" height="100%">
                    <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0" >
                        <Box overflow="hidden">
                        </Box>
                        <table style={{ width: '100%' }}>
                            <thead style={{
                                position: "sticky",
                                top: 0,
                                zIndex: 10,
                                backgroundColor: "#f5f5f5",
                            }}>
                                <tr>
                                    <td>Enabled</td>
                                    <td>Name</td>
                                </tr>
                            </thead>
                            <tbody>
                                {dacs.map((item, index) => (
                                    <tr style={{ backgroundColor: selectedDAC.includes(item.id) ? 'red' : 'inherit' }}>
                                        <td>{item.enabled ? '✅' : 'X'}</td>
                                        <td>{item.element_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Box>
                </Box>
            </Box>

        </Box >)
}
export default UserAccessControllManagement
