import { Box } from '@mui/material';
import TitleBox from './Title';

export default function RecentActivities() {
    const refreshTime = new Date().toLocaleTimeString();

    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
            <TitleBox title="Recent Activities" refreshTime={refreshTime} error={false} onRefresh={() => { }} />
            <Box
                width="100%"
                height={200}
                bgcolor="lightgreen"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                {/* Additional content can go here */}
            </Box>
        </Box>
    );
}

