import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
    Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Checkbox,
    Paper, IconButton, Tooltip, Skeleton, Typography, Select, MenuItem, InputLabel
} from '@mui/material';
import ReservationPopUp from '../reservations/reservationpopup';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './clickablecell.css';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import MonthlyBreakDownDialog from './allsales/MonthlyBreakDown';
import { monthsarray } from '../../common/months';
import { orangeColor } from '../../css/common_sx';

const colorRanges = [
    { threshold: 30000, color: '#ff6666' }, // Red for high prices
    { threshold: 10000, color: '#ffff66' }, // Yellow for medium-high prices
    { threshold: 5000, color: '#ffcc66' },  // Orange for medium prices
    { threshold: 1000, color: '#66cc66' },  // Green for lower prices
    { threshold: 0, color: '#66b2ff' }      // Blue for low prices
];

function MultilineTextDialog({ open, onClose, stayid }) {
    const [text, setText] = useState('');

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleSave = async () => {
        await approveData(text, stayid);
        onClose();
    };

    const approveData = async (text, lstayid) => {
        await axios.post('/Office/Statistics/SalesReport/zerorent/allow/' + lstayid, { memo: text || '' })
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Approve reservation for removel from statistics</DialogTitle>
            <DialogContent>
                <TextField
                    label="Your Text"
                    multiline
                    rows={4}
                    value={text}
                    onChange={handleTextChange}
                    variant="outlined"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ZeroRentDialog({ visible, showReservation }) {
    const [data, setData] = useState([]);
    const [showApproveZeroStay, setshowApproveZeroStay] = useState(false);
    const [stayid, setStayID] = useState(null);
    const [loading, setLoading] = useState(true);
    const fetchData = () => {
        setLoading(true);
        axios.get('/Office/Statistics/SalesReport/zerorent')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    useEffect(() => {
        if (visible) {
            fetchData();
        }
    }, [visible]);

    const renderSkeletonLoader = () => {
        return Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
                <TableCell><Skeleton variant="text" /></TableCell>
                <TableCell><Skeleton variant="text" /></TableCell>
                <TableCell><Skeleton variant="text" /></TableCell>
                <TableCell><Skeleton variant="text" /></TableCell>
                <TableCell><Skeleton variant="text" /></TableCell>
                <TableCell><Skeleton variant="text" /></TableCell>
                <TableCell><Skeleton variant="text" /></TableCell>
            </TableRow>
        ));
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Property</TableCell>
                            <TableCell>OTA</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Date From</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Rent</TableCell>
                            <TableCell>Guest Price</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? renderSkeletonLoader() :
                            data.map((item, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                    >
                                        <TableCell>{item.listing_name}</TableCell>
                                        <TableCell>{item.ota_name}</TableCell>
                                        <TableCell>{item.guest_name}</TableCell>
                                        <TableCell>{new Date(item.date_from).toLocaleDateString('un-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</TableCell>
                                        <TableCell>{item.total_price}</TableCell>
                                        <TableCell>{item.total_rent}</TableCell>
                                        <TableCell>{item.guest_price}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Approve and hide">
                                                <IconButton t onClick={() => {
                                                    setStayID(item.id);
                                                    setshowApproveZeroStay(true);
                                                }}>
                                                    <DoneOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Go to reservaion">
                                                <IconButton t onClick={() => {
                                                    setshowApproveZeroStay(false);
                                                    showReservation(item.reservation_id)
                                                }}>
                                                    < ArrowForwardIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <MultilineTextDialog open={showApproveZeroStay}
                onClose={(text) => {
                    setStayID(null);
                    setshowApproveZeroStay(false);
                    fetchData();
                }
                } stayid={stayid} />
        </Box>
    );
}

export default function AllSalesReport() {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false); // State to track dialog open status

    const [selectedYear, setSelectedYear] = useState(2024);

    const [backupOpen, setBackupOpen] = useState(false); // State to track backup dialog open status
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [months, setMonths] = useState([]);
    const [propertiesGrouped, setPropertiesGrouped] = useState({});
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [backups, setBackups] = useState([]); // State to store Backups
    const [zeroRentReservation, setzeroRentReservations] = useState(0);
    const [showzeroRentReservation, setshowzeroRentReservations] = useState(false);
    const [reservationID, setreservationID] = useState(null);
    const [showReservationPopup, setshowReservationPopup] = useState(false);
    const tableRef = useRef(null);

    const [showMontlyBreakDown, setshowMontlyBreakDown] = useState(false);
    const [monthlyBreakDownSelectedMonth, setMonthlyBreakDownSelectedMonth] = useState(null);
    const [monthlyBreakDownSelectedProperty, setMonthlyBreakDownSelectedProperty] = useState(null);

    const fetchData = (year) => {
        axios.get(`/Office/Statistics/SalesReport/allproperties?year=${year}`)
            .then(response => {
                const groupedData = {};

                response.data.forEach(item => {
                    const { listing_name, ota_name, res_month, total_price, total_rent, listing_id } = item;
                    const month = new Date(res_month).getMonth() + 1; // Extract month as a number (1-12)

                    if (!groupedData[listing_name]) {
                        groupedData[listing_name] = {
                            pid: listing_id
                        }
                    }

                    if (!groupedData[listing_name][ota_name]) {
                        groupedData[listing_name][ota_name] = {};
                    }

                    // Convert total_price and total_rent to numbers for summation
                    groupedData[listing_name][ota_name][month] = {
                        total_price: parseFloat(total_price.replace(/[^0-9.-]+/g, "")) || 0,
                        total_rent: parseFloat(total_rent.replace(/[^0-9.-]+/g, "")) || 0
                    };
                });
                setPropertiesGrouped(groupedData);
            })
            .catch(error => {
                console.error('Error fetching sales data:', error);
            });

    };

    useEffect(() => {
        fetchData(selectedYear);
    }, [selectedYear]);


    useEffect(() => {

        // Generate days and months for 2024
        setMonths(monthsarray);

        // Fetch backup data (mock example)
        setBackups(['Backup1 - 01/01/2024', 'Backup2 - 02/01/2024', 'Backup3 - 03/01/2024']);
        setSelectedYear(2024);
    }, []);

    const filteredProperties = searchQuery.length > 0 ?
        Object.keys(propertiesGrouped).filter(listingName =>
            listingName.toLowerCase().includes(searchQuery.toLowerCase())
        ) : Object.keys(propertiesGrouped);

    // Helper function to calculate monthly grand totals
    const calculateMonthlyTotals = () => {
        const monthlyTotals = months.map(() => ({ total_price: 0, total_rent: 0 }));

        // Iterate over only the filtered properties
        filteredProperties.forEach(listingName => {
            const otaData = propertiesGrouped[listingName];
            Object.values(otaData).forEach(monthData => {
                months.forEach((month, idx) => {
                    if (typeof monthData === 'object' && monthData[month.number]) {
                        monthlyTotals[idx].total_price += monthData[month.number].total_price;
                        monthlyTotals[idx].total_rent += monthData[month.number].total_rent;
                    }
                });
            });
        });

        return monthlyTotals;
    };

    // Helper function to calculate yearly grand total per property
    const calculateYearlyTotalsPerProperty = (otaData) => {
        return months.reduce(
            (totals, month) => {
                Object.values(otaData).forEach(monthData => {
                    if (typeof monthData === 'object') {
                        if (monthData[month.number]) {
                            totals.total_price += monthData[month.number].total_price;
                            totals.total_rent += monthData[month.number].total_rent;
                        }
                    }
                });
                return totals;
            },
            { total_price: 0, total_rent: 0 }
        );
    };

    // Helper function to calculate monthly totals per property
    const calculateMonthlyTotalsPerProperty = (otaData) => {
        return months.map(month => {
            let monthlyTotal = { total_price: 0, total_rent: 0 };
            Object.values(otaData).forEach(monthData => {
                if (typeof monthData === 'object') {
                    if (monthData[month.number]) {
                        monthlyTotal.total_price += monthData[month.number].total_price;
                        monthlyTotal.total_rent += monthData[month.number].total_rent;
                    }
                }
            });
            return monthlyTotal;
        });
    };

    const monthlyTotals = calculateMonthlyTotals();

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(num);
    };


    const getBackgroundColor = (value) => {
        // Find the appropriate color based on the value
        const colorRange = colorRanges.find(range => value >= range.threshold);
        return colorRange ? colorRange.color : '#66b2ff'; // Default to blue if not found
    };

    return (
        <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                bgcolor={orangeColor}
                borderRadius={4}
                boxShadow={3}  // Optional: Adds depth with a subtle shadow
            >
                {/* Year Selector */}
                <Box display="flex" flexDirection="column" marginRight={2}>
                    <Select
                        labelId="demo-simple-select-label"
                        value={selectedYear}
                        onChange={(item) => setSelectedYear(item.target.value)}
                        variant="outlined"
                        sx={{ minWidth: 120 }}  // Ensures proper width for the select input
                    >
                        <MenuItem value={2024}>2024</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                    </Select>
                </Box>

                {/* Search Field */}
                <TextField
                    label="Search by Property Name"
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ flex: 1, marginRight: 2 }}  // Flex for expanding the search input and margin for spacing
                />

                {/* Color Range Legend */}
                <Box display="flex" flexDirection="row" marginRight={2}>
                    {colorRanges.map((range, index) => (
                        <Box key={index} display="flex" alignItems="center" marginLeft={index > 0 ? 2 : 0}>
                            <Box width="20px" height="20px" bgcolor={range.color} marginRight="5px" borderRadius={1} />
                            <Typography>{`≥ ${range.threshold}`}</Typography>
                        </Box>
                    ))}
                </Box>

                {/* Buttons */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setBackupOpen(true)}
                    sx={{ marginRight: 2 }}
                >
                    View Backups
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setshowzeroRentReservations(true)}
                    sx={{ marginRight: 2 }}
                >
                    Zero Rent Reservations ({zeroRentReservation})
                </Button>

                {/* Excel Export */}
                <DownloadTableExcel
                    filename="users_table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                >
                    <Button variant="contained">
                        Export Excel
                    </Button>
                </DownloadTableExcel>
            </Box>
            <Box position="relative" width="100%" height="100%">
                <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0">
                    <table ref={tableRef} className='styled-table' style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'orange', zIndex: 1 }}>
                            <tr>
                                <th rowSpan={2} style={{ minWidth: '170px', textAlign: 'center', border: "1px solid black", padding: '4px' }}>
                                    Properties
                                </th>
                                {months.map((month) => (
                                    <th key={month.number} colSpan={2} style={{ textAlign: 'center', border: "1px solid black", padding: '4px' }}>
                                        {month.number} - {month.name}
                                    </th>
                                ))}
                                <th colSpan={2} style={{ textAlign: 'center', border: "1px solid black", padding: '4px' }}>
                                    Yearly Total
                                </th>
                            </tr>
                            <tr>
                                {months.map((month) => (
                                    <React.Fragment key={month.number}>
                                        <th style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px' }}>Price</th>
                                        <th style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px' }}>Rent</th>
                                    </React.Fragment>
                                ))}
                                <th style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px' }}>Price</th>
                                <th style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px' }}>Rent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProperties.length > 0 ? (
                                filteredProperties.map((listingName, index) => {
                                    const yearlyTotals = calculateYearlyTotalsPerProperty(propertiesGrouped[listingName]);
                                    const monthlyTotalsPerProperty = calculateMonthlyTotalsPerProperty(propertiesGrouped[listingName]);

                                    return (
                                        <React.Fragment key={listingName}>
                                            <tr>
                                                <td style={{ fontWeight: 'bold', border: "1px solid black", position: 'sticky', left: 0, backgroundColor: 'lightblue', padding: '4px' }}>
                                                    {listingName}
                                                </td>
                                                {months.map((month, monthIdx) => (
                                                    <React.Fragment key={month.number}>
                                                        <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px' }}>
                                                            {/* Placeholder if needed */}
                                                        </td>
                                                        <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px' }}>
                                                            {/* Placeholder if needed */}
                                                        </td>
                                                    </React.Fragment>
                                                ))}
                                                <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px', backgroundColor: getBackgroundColor(yearlyTotals.total_price) }}>
                                                    {formatNumber(yearlyTotals.total_price)}
                                                </td>
                                                <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px', backgroundColor: getBackgroundColor(yearlyTotals.total_rent) }}>
                                                    {formatNumber(yearlyTotals.total_rent)}
                                                </td>
                                            </tr>
                                            {Object.keys(propertiesGrouped[listingName]).filter((item) => item != 'pid').sort().map((otaName, idx) => (
                                                <tr key={`${listingName}-${otaName}`}>
                                                    <td style={{ paddingLeft: '20px', position: 'sticky', left: 0, backgroundColor: 'lightblue', border: "1px solid black" }}>
                                                        {otaName}
                                                    </td>
                                                    {months.map((month) => (
                                                        <React.Fragment key={month.number}>
                                                            <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px', backgroundColor: getBackgroundColor(propertiesGrouped[listingName][otaName][month.number]?.total_price || 0) }}>
                                                                {propertiesGrouped[listingName][otaName][month.number]?.total_price
                                                                    ? formatNumber(propertiesGrouped[listingName][otaName][month.number].total_price)
                                                                    : ''}
                                                            </td>
                                                            <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px', backgroundColor: getBackgroundColor(propertiesGrouped[listingName][otaName][month.number]?.total_rent || 0) }}>
                                                                {propertiesGrouped[listingName][otaName][month.number]?.total_rent
                                                                    ? formatNumber(propertiesGrouped[listingName][otaName][month.number].total_rent)
                                                                    : ''}
                                                            </td>
                                                        </React.Fragment>
                                                    ))}
                                                    <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px', backgroundColor: getBackgroundColor(Object.values(propertiesGrouped[listingName][otaName]).reduce((sum, monthData) => sum + monthData.total_price, 0)) }}>
                                                        {/* OTA Channel yearly totals */}
                                                        {formatNumber(
                                                            Object.values(propertiesGrouped[listingName][otaName])
                                                                .reduce((sum, monthData) => sum + monthData.total_price, 0)
                                                        )}
                                                    </td>
                                                    <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px', backgroundColor: getBackgroundColor(Object.values(propertiesGrouped[listingName][otaName]).reduce((sum, monthData) => sum + monthData.total_rent, 0)) }}>
                                                        {formatNumber(
                                                            Object.values(propertiesGrouped[listingName][otaName])
                                                                .reduce((sum, monthData) => sum + monthData.total_rent, 0)
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr style={{ fontWeight: 'bold', backgroundColor: '#f9f9f9' }}>
                                                <td style={{ border: "1px solid black", padding: '4px', position: 'sticky', left: 0, backgroundColor: 'lightsalmon' }}>Monthly Totals</td>
                                                {monthlyTotalsPerProperty.map((total, idx) => (
                                                    <React.Fragment key={`${listingName}-monthlytotal-${idx}`}>
                                                        <td className='clickable' onClick={() => {
                                                            setMonthlyBreakDownSelectedProperty(propertiesGrouped[listingName].pid);
                                                            setMonthlyBreakDownSelectedMonth(months[idx].number);
                                                            setshowMontlyBreakDown(true);
                                                        }} style={{
                                                            minWidth: '80px', textAlign: 'center',
                                                            border: "1px solid black", padding: '4px',
                                                            backgroundColor: 'lightsalmon'
                                                        }}>
                                                            {formatNumber(total.total_price)}
                                                        </td>
                                                        <td className='clickable' onClick={() => {
                                                            setMonthlyBreakDownSelectedProperty(propertiesGrouped[listingName].pid);
                                                            setMonthlyBreakDownSelectedMonth(months[idx].number);
                                                            setshowMontlyBreakDown(true);
                                                        }} style={{
                                                            minWidth: '80px', textAlign: 'center',
                                                            border: "1px solid black", padding: '4px',
                                                            backgroundColor: 'lightsalmon'
                                                        }}>
                                                            {formatNumber(total.total_rent)}
                                                        </td>
                                                    </React.Fragment>
                                                ))}
                                                <td colSpan={2} style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px' }}>
                                                    {/* Placeholder for overall yearly total */}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={months.length * 2 + 3} style={{ textAlign: 'center', padding: '20px' }}>
                                        No properties found
                                    </td>
                                </tr>
                            )}
                            <tr style={{ fontWeight: 'bold' }}>
                                <td style={{ border: "1px solid black", padding: '4px', position: 'sticky', left: 0, backgroundColor: 'lightsalmon' }}>Overall Monthly Totals</td>
                                {monthlyTotals.map((total, idx) => (
                                    <React.Fragment key={idx}>
                                        <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px', backgroundColor: getBackgroundColor(total.total_price) }}>
                                            {formatNumber(total.total_price)}
                                        </td>
                                        <td style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px', backgroundColor: getBackgroundColor(total.total_rent) }}>
                                            {formatNumber(total.total_rent)}
                                        </td>
                                    </React.Fragment>
                                ))}
                                <td colSpan={2} style={{ minWidth: '80px', textAlign: 'center', border: "1px solid black", padding: '4px' }}>
                                    {formatNumber(monthlyTotals.reduce((sum, cur) => sum + cur.total_rent, 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>

            {/* Dialog for showing days in the selected month */}
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
                <DialogTitle>{months[selectedMonth] ? months[selectedMonth].name : ''} - 2024 for {selectedProperty}</DialogTitle>
                <DialogContent dividers>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showzeroRentReservation} onClose={() => setshowzeroRentReservations(false)} maxWidth="lg" fullWidth>
                <DialogTitle>Zero Rent Reservations</DialogTitle>
                <DialogContent dividers>
                    <ZeroRentDialog visible={showzeroRentReservation} showReservation={(e) => {
                        setshowzeroRentReservations(false);
                        setreservationID(e);
                        setshowReservationPopup(true);
                    }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setshowzeroRentReservations(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for showing backups */}
            <Dialog open={backupOpen} onClose={() => setBackupOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Backups</DialogTitle>
                <DialogContent dividers>
                    <ul>
                        {backups.map((backup, idx) => (
                            <li key={idx}>{backup}</li>
                        ))}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBackupOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <MonthlyBreakDownDialog open={showMontlyBreakDown} year={selectedYear} onClose={() => {
                setshowMontlyBreakDown(false);
                setMonthlyBreakDownSelectedMonth(null);
                setMonthlyBreakDownSelectedProperty(null);
            }} property={monthlyBreakDownSelectedProperty} month={monthlyBreakDownSelectedMonth} />
            <ReservationPopUp
                reservationID={reservationID}
                visible={showReservationPopup}
                onclose={() => { setshowReservationPopup(false) }}
            />
        </Box>
    );
}

