import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { Tree } from 'primereact/tree';
import { classNames } from 'primereact/utils';
import { ContextMenu } from 'primereact/contextmenu';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';



const DevicesView = () => {
    const [treeData, setTreeData] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const toggleRowExpansion = (index) => {
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setExpandedRows([...expandedRows, index]);
        }
    };
    useEffect(() => {
        // Fetch data from API
        axios.get('http://localhost:3001/LeviathanMonitoring/Devices')
            .then(response => {
                const data = response.data;

                setTreeData(data);
                console.log(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);
    return (
        <Box height="100%" width="100%" flex="1" flexDirection="column" display="flex">
            <Box display="flex" flexDirection="row">
                <Typography>Search</Typography>
                <InputText></InputText>
            </Box>
            <Box position="relative" width="100%" height="100%">
                <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0" >
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "24px" }}></th>
                                <th style={{ width: "32px" }}>ID</th>
                                <th style={{ flex: "1" }}>Name</th>
                                <th style={{ width: "120px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {treeData.map((item) => (
                                <React.Fragment key={item.id}>
                                    <tr>
                                        <td>
                                            <Button
                                                icon={expandedRows.includes(item.id) ? "pi pi-chevron-down" : "pi pi-chevron-right"}
                                                onClick={() => toggleRowExpansion(item.id)}
                                            />
                                        </td>
                                        <td>
                                            {item.id}
                                        </td>
                                        <td>
                                            {item.device_name}
                                        </td>
                                        <td>
                                            <Box display='flex' flexDirection='row'>
                                                <Button tooltip='Syslog' icon={"pi pi-database"}></Button>
                                                <Button tooltip='Options' icon={"pi pi-cog"}></Button>
                                            </Box>
                                        </td>
                                    </tr>
                                    {expandedRows.includes(item.id) && (
                                        <React.Fragment>
                                            <tr>
                                                <td colSpan={4}>
                                                    <table style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>DCI ID</th>
                                                                <th>DCI Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.dcis.map((dci) => (
                                                                <tr>
                                                                    <td>
                                                                        {dci.dci_id}
                                                                    </td>
                                                                    <td>{dci.dci_name}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </React.Fragment>)}
                                </ React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    )
}

const MonitoringView = () => {
    const [dashItems, setDashItems] = useState([]);
    const cm = useRef(null);

    useState(() => {
        setDashItems([<DevicesView />])
    }, [])
    return (
        <Box display="flex" flexDirection="row" flex="1" width="100%" height="100%" margin="5px">
            {/*<Box height="100%" width="100%" flex="1" flexDirection="column" display="flex">
                <Box>
                    <Typography variant="h6">Monitoring</Typography>
                </Box>
                <Box flex="1" width="100%" position="relative">
                    <ContextMenu model={contextMenu} ref={cm} />
                    <Tree value={treeData} expandedKeys={expandedKeys} onToggle={(e) => setExpandedKeys(e.value)}
                        contextMenuSelectionKey={selectedNodeKey} onContextMenuSelectionChange={(e) => setSelectedNodeKey(e.value)}
                        onContextMenu={(e) => { updateMenu(); cm.current.show(e.originalEvent) }} className="w-full md:w-30rem" />
                </Box>
            </Box>
            <Box flex="5" width="100%" height="100%" display="flex" flexDirection="column">
                <Box justifyContent="start" alignItems="center" display="flex" flexDirection="row">
                    <Typography variant="h6" marginRight="16px">Period Type:</Typography>

                </Box>
                <PaceItem compare_type={selectedCompareType} period_type={selectedPerdiodType} property_id={selectedProperties} />
                <Box position="relative" width="100%" height="100%">
                    <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0" >
                    </Box>
                </Box>

            </Box >
        */}
            {dashItems}
        </Box >


    )
}
export default MonitoringView
