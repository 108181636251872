import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, List, ListItem, ListItemText, ListItemIcon, Checkbox, Avatar, TextField, IconButton, InputAdornment, Tabs, Tab, Skeleton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import { CommonSX, orangeColor } from '../../css/common_sx';
export function PropertiesList({ onSelect }) {
    const [properties, setProperties] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [isLoading, setIsLoading] = useState(true);  // Loading state

    const fetchProperties = async () => {
        setIsLoading(true);
        let response = await axios.get('/StaffGetProperties?hier_id=12&type_id=5');
        setProperties(response.data.MainPropertyList);
        setFilteredProperties(response.data.MainPropertyList);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    const handleToggle = (value) => () => {
        const currentIndex = selectedProperties.indexOf(value);
        const newChecked = [...selectedProperties];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedProperties(newChecked);
        onSelect(newChecked);
    };

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        setFilteredProperties(
            properties.filter((property) =>
                property.name.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleClearSearch = () => {
        setSearchTerm('');
        setFilteredProperties(properties);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const renderSkeleton = () => (
        <Box>
            {[...new Array(5)].map((_, index) => (
                <ListItem key={index} dense>
                    <ListItemIcon>
                        <Skeleton variant="circular" width={40} height={40} />
                    </ListItemIcon>
                    <ListItemText primary={<Skeleton width="80%" />} secondary={<Skeleton width="60%" />} />
                </ListItem>
            ))}
        </Box>
    );

    return (
        <Box flex="1" width="100%" position="relative" display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column" bottom="0" top="0" height="100%">
                <TextField
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Search properties"
                    variant="outlined"
                    sx={CommonSX}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClearSearch}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Box flex={1}>
                    {selectedTab === 0 && (
                        <Box flex="1" height="100%" position="relative">
                            <List
                                style={{ color: orangeColor, position: 'absolute', overflow: 'auto', top: '0', bottom: '0px', left: '0', right: '0' }}
                            >
                                {isLoading ? renderSkeleton() : filteredProperties.map((property) => (
                                    <ListItem
                                        key={property.property_id}
                                        dense
                                        button
                                        onClick={handleToggle(property)}
                                        sx={{
                                            backgroundColor: selectedProperties
                                                .findIndex(selected =>
                                                    selected.property_id === property.property_id) !== -1 ? 'rgba(128, 128, 170, 0.4)' : 'transparent'
                                        }}>
                                        <Box display="flex" flexDirection="column" width="100%">
                                            <Box display="flex" flexDirection="row" alignItems="center" width="100%" >
                                                <ListItemIcon>
                                                    <Avatar sx={{ color: orangeColor }} src={property.listing_img} />
                                                </ListItemIcon>
                                                <ListItemText primary={property.name} secondary={
                                                    <Box display="flex" flexDirection="row" alignItems="center" >
                                                        <Checkbox
                                                            edge="start"
                                                            checked={selectedProperties.findIndex(selected => selected.property_id === property.property_id) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                        />
                                                        {property.otas !== undefined && property.otas.map((item, index) => (
                                                            <Avatar
                                                                key={index}
                                                                src={`https://pcrms.leviathan.systems${item}`}
                                                                alt={`OTA ${index}`}
                                                                style={{ width: '16px', height: '16px', marginRight: '5px' }}
                                                            />
                                                        ))}
                                                    </Box>
                                                } />
                                            </Box>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    )}
                </Box>
                <Box maxHeight="50px" flex={1} width="100%">
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="icon tabs">
                        <Tab icon={<HomeIcon />} aria-label="home" />
                        <Tab icon={<GroupIcon />} aria-label="group" />
                    </Tabs>
                </Box>
            </Box>
        </Box >
    );
}

